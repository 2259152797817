import React, { useState, useEffect } from 'react';

//Imports librerias externas
import axios from 'axios';

//Imports componentes auxiliares
import { guardarSesion } from '../../Utils/Utils';
import { URL_API } from '../../Config/config';
import languageJson from '../../Utils/language.json';

const Step2 = ({ setPaso, GC }) => {
  // Configurar encabezados personalizados para evitar problemas de CORS
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  //Context
  const { user, modificarUser, language } = GC;

  const [boton, setBoton] = useState('boton-disabled'); // State para claseName de boton "Continuar"
  const [input, setInput] = useState('input'); // State para className de input

  const [isCodigoEnviado, setisCodigoEnviado] = useState(false)

  const [codigo, setCodigo] = useState({ digito1: '', digito2: '', digito3: '', digito4: '', digito5: '' }); // State para codigo de acceso

  const setDigitoInput = (value, input) => {
    const digito = 'digito' + input;
    setCodigo({ ...codigo, [digito]: value });
    if (input !== 5) {
      const siguienteInput = document.getElementById('digito' + (input + 1));
      siguienteInput.focus();
    }
  };

  async function resendCode() {
    axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`;
    try {
      const response = await axios.post(URL_API + 'api/authentication/resend-code', { email: user.email });

      let newUser = user;
      newUser.code = response.data.code;

      modificarUser(newUser);
      guardarSesion(newUser);
      setisCodigoEnviado(true)
      return response.data.success;
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  const comprobarCodigo = React.useCallback(() => {
    if (
      codigo.digito1 !== '' &&
      codigo.digito2 !== '' &&
      codigo.digito3 !== '' &&
      codigo.digito4 !== '' &&
      codigo.digito5 !== '' &&
      user
    ) {
      const codigoNew = Object.values(codigo).join('');
      return codigoNew === user.code;
    } else return false;
  }, [codigo, user]);

  const continuar = () => {
    if (comprobarCodigo()) {
      let newUser = structuredClone(user);
      newUser.validate = true;

      modificarUser(newUser);
      guardarSesion(newUser);

      setPaso(3);
    }
  };

  // Validaciones en tiempo real para paso de registro
  useEffect(() => {
    user?.validate && setPaso(3); //Validacion de usuario
    if (comprobarCodigo()) {
      setBoton('boton-enabled');
      setInput('input-success');
    } else if (
      codigo.digito1 !== '' &&
      codigo.digito2 !== '' &&
      codigo.digito3 !== '' &&
      codigo.digito4 !== '' &&
      codigo.digito5 !== ''
    ) {
      setBoton('boton-disabled');
      setInput('input-error');
    }
  }, [comprobarCodigo, codigo, user, setPaso, setBoton, setInput]);

  return (
    <div className="formulario-codigo direction-column justify-center align-start">
      <div className="espacio-titulo">
        <img src="/img/logo-cab-risk.png" className="logo-img" alt="logo" />
        <img src="/img/titulo-cab-risk.png" className="titulo-img" alt="titulo" />
      </div>
      <p className="titulo-2">{languageJson[language].SignUp.Step2.titulo1 + (user ? user.email : '')}</p>
      <div className="espacio-input-codigo">
        <input
          type="number"
          id="digito1"
          className={input}
          value={codigo.digito1}
          maxLength={1}
          max={1}
          onChange={(e) => setDigitoInput(e.target.value, 1)}
          onClick={() => setCodigo({ ...codigo, digito1: '' })}
        />
        <input
          type="number"
          id="digito2"
          className={input}
          value={codigo.digito2}
          maxLength={1}
          max={1}
          onChange={(e) => setDigitoInput(e.target.value, 2)}
          onClick={() => setCodigo({ ...codigo, digito2: '' })}
        />
        <input
          type="number"
          id="digito3"
          className={input}
          value={codigo.digito3}
          maxLength={1}
          max={1}
          onChange={(e) => setDigitoInput(e.target.value, 3)}
          onClick={() => setCodigo({ ...codigo, digito3: '' })}
        />
        <input
          type="number"
          id="digito4"
          className={input}
          value={codigo.digito4}
          maxLength={1}
          max={1}
          onChange={(e) => setDigitoInput(e.target.value, 4)}
          onClick={() => setCodigo({ ...codigo, digito4: '' })}
        />
        <input
          type="number"
          id="digito5"
          className={input}
          value={codigo.digito5}
          maxLength={1}
          max={1}
          onChange={(e) => setDigitoInput(e.target.value, 5)}
          onClick={() => setCodigo({ ...codigo, digito5: '' })}
        />
      </div>
      {input === 'input-error' && <p className="texto-error">{languageJson[language].SignUp.Step2.textoError}</p>}
      <button className={`boton-continuar ${boton}`} onClick={() => continuar()}>
        {languageJson[language].SignUp.Step2.textoBoton}
      </button>
      <button className="boton-reenviar-codigo" onClick={() => resendCode()}>
        {languageJson[language].SignUp.Step2.textoBotonReenviar}
      </button>
     {isCodigoEnviado&& <span style={{color:"white", marginTop:"1rem", marginLeft:"10px"}}>{languageJson[language].SignUp.Step2.textoConfirmacionReenviarCodigo}</span>}
    </div>
  );
};

export default Step2;
