import React from 'react';

const SPAIN = ({ language }) => {
  const renderSvgByCategory = (language) => {
    switch (language) {
      case 'ES':
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="none">
                <path d="M0.75 9.33333V4H23.25V9.33333L12 9.77778L0.75 9.33333Z" fill="#D6002A"/>
                <path d="M0.75 19.9999V14.6666L12 14.2222L23.25 14.6666V19.9999H0.75Z" fill="#D6002A"/>
                <path d="M0.75 9.3335H23.25V14.6668H0.75V9.3335Z" fill="#FDDB47"/>
            </svg>
        );
      case 'EN':
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" opacity = "0.5" viewBox="0 0 24 24" fill="none">
                <path d="M0.75 9.33333V4H23.25V9.33333L12 9.77778L0.75 9.33333Z" fill="#D6002A"/>
                <path d="M0.75 19.9999V14.6666L12 14.2222L23.25 14.6666V19.9999H0.75Z" fill="#D6002A"/>
                <path d="M0.75 9.3335H23.25V14.6668H0.75V9.3335Z" fill="#FDDB47"/>
            </svg>
            
        );
      default:
        return null;
    }
  };

  return (
      renderSvgByCategory(language)
  );
};

export default SPAIN;
