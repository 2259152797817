import React from 'react'

//Imports externos
import Modal from 'react-modal';

import './GeneralModal.scss'

const GeneralModal = ({modalIsOpen, setIsOpen, contentLabel, contentStyles, children}) => {

    const  contentDefault = {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }

    //Funcion para cerrar el modal segun el state por parametro.
    function closeModal() { setIsOpen(false) }

    return ( 
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style = {{content: {...contentDefault, ...contentStyles}}}
            contentLabel= {contentLabel}
            overlayClassName="Overlay"
        >
            {children}
        </Modal>
     );
}
 
export default GeneralModal;