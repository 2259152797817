import React, { useReducer, useEffect, useRef } from 'react';

import axios from 'axios';

import GeneralContext from './GeneralContext';
import GeneralReducer from './GeneralReducer';

import { obtenerSesion, guardarSesion, isEqual } from '../Utils/Utils';
import { eliminarSesion } from '../Utils/Utils';

import { WS_API, URL_API } from '../Config/config';

import { MODIFICAR_USER, LOADING, LANGUAGE } from '../types';

const GeneralState = (props) => {
  const initialState = {
    user: null,
    isLoading: false,
    language: 'ES',
  };

  //dispatch para ejecutar las acciones
  const [state, dispatch] = useReducer(GeneralReducer, initialState);
  const isUserUpdated = useRef(false);

  //Serie de funciones
  const modificarUser = (user) => {
    dispatch({ type: MODIFICAR_USER, payload: user });
  };

  const modificarLoading = (loading) => {
    dispatch({ type: LOADING, payload: loading });
  };

  const modificarLanguaje = (language) => {
    dispatch({ type: LANGUAGE, payload: language });
  };

  //Funcion auxliar para consultar usuario
  const consultarUsuario = React.useCallback(
    async (userId) => {      
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${state.user?.bearer}`;
        const response = await axios.get(URL_API + `api/user/${userId}`);
        if (response.data.success) {
          let newUser = response.data.user;
          newUser.bearer = response.data.user.token;
          newUser.code = state.user?.code;
          newUser.validate = !!state.user?.validate;
          isUserUpdated.current = true;

          if (!isEqual(newUser, state.user)) {
            console.log('Se modifico el usuario');
            if (newUser.email !== state.user?.email) {
              eliminarSesion();
              modificarLoading(false);
              window.location.reload();
              return
            }
            modificarUser(newUser);
            guardarSesion(newUser);
          } else {
            console.log('No se modifico el usuario');
          }
        } else {
          console.log('Error al consultar el usuario');
        }

        modificarLoading(false);
      } catch (error) {
        console.error('Error:', error);
        modificarLoading(false);
        return false;
      }
    },
    [state.user]
  );
  useEffect(() => {
    state.user && !isUserUpdated.current && consultarUsuario(state.user._id);
  }, [state.user]);

  useEffect(() => {
    const ws = new WebSocket(WS_API);
    try {

      ws.onopen = () => {
        console.log('Conexión WebSocket establecida');
      };

      ws.onmessage = (event) => {       
        const updatedUserInfo = JSON.parse(event.data);
        if (updatedUserInfo.documentKey._id === state.user?._id) {
          modificarLoading(true);
          consultarUsuario(state.user._id);
        }
      };
    } catch (error) {
      console.log(error);
    }
   

    //toma de datos de localstorage para mantener sesion
    const newUser = obtenerSesion();
    newUser && !state.user && modificarUser(newUser);

     return () => {
    // Cleanup: Close WebSocket connection when component unmounts
           ws.close();
    };
  }, [state.user, consultarUsuario]);

  return (
    <GeneralContext.Provider
      value={{
        user: state.user,
        isLoading: state.isLoading,
        language: state.language,
        modificarUser,
        modificarLoading,
        modificarLanguaje,
      }}>
      {props.children}
    </GeneralContext.Provider>
  );
};

export default GeneralState;
