import React, { useState, useEffect } from 'react'

//Imports librerias externas
import axios from 'axios';
import { toast, Toaster } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';


//Imports componentes auxiliares
import GeneralModal from '../../Utils/GeneralModal/GeneralModal'
import CheckoutPaypal from '../../Utils/Checkout/CheckoutPaypal';

import { STRIPE_KEY, STRIPE_LINK_EXPRESS, STRIPE_LINK_REGULAR, URL_API } from '../../Config/config';
import { guardarSesion } from '../../Utils/Utils';
import languageJson from '../../Utils/language.json';

const Step4 = ({ GC }) => {

    //Context
    const { user, modificarUser, modificarLoading, language } = GC;

    const navigate = useNavigate();

    const [modalIsOpen, setModalIsOpen] = useState(false); //State para manejo de modales
    const [modalAdvertencia, setModalAdvertencia] = useState(false); //State para manejo de modales
    const [modalPago, setModalPago] = useState(false); //State para manejo de modales
    const [modalError, setModalError] = useState(false); //State para manejo de modales

    const [opcionPago, setOpcionPago] = useState(null)
    const [boton, setBoton] = useState("boton-disabled") // State para claseName de boton "Continuar"

    const [idPaymentStripe, setIdPaymentStripe] = useState(null)


    const pagoModal = () => {
        const idStripe = uuidv4();
        setIdPaymentStripe(idStripe);
        setModalAdvertencia(false);
        setModalPago(true);
        pagoStripe(idStripe);
    }

    const pagoCoinpayments = async () => {
        try {
            modificarLoading(true);
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`
            const response = await axios.post(URL_API + 'api/payment-coinpayment', { "balance": (opcionPago === 1) ? "67" : "77" })

            if (response.data.success)
                navigate(response.data.checkout_url);
            else
                toast.error(languageJson[language].ToastMessage.error9)
            modificarLoading(false);
        } catch (error) {
            console.error('Error:', error);
            modificarLoading(false);
            return false;
        }
    }

    const pagoStripe = React.useCallback(async (ID_PAYMENT) => {
        console.log("pagoStripe")
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`
            const response = await axios.post(URL_API + 'api/payments', {
                "payment_method": "Stripe",
                "status_payment": "PENDING",
                "payment_type": (opcionPago === 1 ? "Regular" : "Express"),
                "user": user._id,
                "id_payment": ID_PAYMENT,
                "amount": (opcionPago === 1) ? "67" : "77",
            })
            if (response.data.success) {
                let newUser = user
                newUser.pago = response.data
                modificarUser(newUser)
                guardarSesion(newUser)
            } else {
                toast.error(languageJson[language].ToastMessage.error9)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }, [user, opcionPago, idPaymentStripe, modificarUser, language])

    //Funcion auxiliar: revisa si el usuario ya realizo el pago y este esta completado
    const revisionPago = React.useCallback(() => {
        for (const pago of user.pagos) {
            if (pago.status_payment === "COMPLETED") {
                navigate("/Formulario");
            }
        }
    }, [user, navigate])

    // Validaciones en tiempo real para paso de registro
    useEffect(() => {
        if (user?.pagos?.length > 0)
            revisionPago()
        if (opcionPago)
            setBoton("boton-enabled");
        else if (boton === "boton-enabled")
            setBoton("boton-disabled");
    }, [user, opcionPago, boton, setBoton, revisionPago])



    return (
        <div className="formulario-pago direction-column justify-center align-start">
            <Toaster position="top-right" reverseOrder={false} />
            <div className="espacio-titulo">
                <img src="/img/logo-cab-risk.png" className="logo-img" alt="logo"></img>
                <img src="/img/titulo-cab-risk.png" className="titulo-img" alt="titulo"></img>
            </div>
            <p className="titulo-1">{languageJson[language].SignUp.Step4.titulo1}</p>
            <p className="titulo-2">{languageJson[language].SignUp.Step4.titulo2}</p>
            <div className='linea-check'>
                <input type="checkbox" className='check' checked={opcionPago === 1} onChange={(e) => (e.target.checked ? setOpcionPago(1) : null)} />
                <span className="check-mark"></span>
                <p className='texto' dangerouslySetInnerHTML={{ __html: languageJson[language].SignUp.Step4.opcion1.texto }}></p>
            </div>
            <div className='linea-check'>
                <input type="checkbox" className='check' checked={opcionPago === 2} onChange={(e) => (e.target.checked ? setOpcionPago(2) : null)} />
                <span className="check-mark"></span>
                <p className='texto' dangerouslySetInnerHTML={{ __html: languageJson[language].SignUp.Step4.opcion2.texto }}></p>
            </div>
            <button className={"boton-continuar " + boton} onClick={() => opcionPago && setModalAdvertencia(true)}>
                {languageJson[language].SignUp.Step4.textoBotonPagar}
            </button>
            <GeneralModal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen} contentLabel={"Cuenta creada"}>
                <div className="modal-cuenta-creada">
                    <div className="fila-titulo">
                        <img src="/img/check.png" className="check-img" alt="check"></img>
                        <p className="titulo">{languageJson[language].LateralVector.titulo1}</p>
                    </div>
                    <p className="texto">{languageJson[language].SignUp.Step4.modalTexto}</p>
                    <button className="boton-continuar" onClick={() => setModalIsOpen(false)}>
                        {languageJson[language].SignUp.Step4.modalBoton}
                    </button>
                </div>
            </GeneralModal>
            <GeneralModal modalIsOpen={modalAdvertencia} setIsOpen={setModalAdvertencia} contentLabel={"Cuenta creada"}>
                <div className="modal-cuenta-creada">
                    <div className="fila-titulo">
                        <img src="/img/advertencia.png" className="check-img" alt="check"></img>
                        <p className="titulo">{languageJson[language].SignUp.Step4.modalAdvertenciaTitulo}</p>
                    </div>
                    <p className="texto">{languageJson[language].SignUp.Step4.modalAdvertenciaTexto}</p>
                    <div className="direction-row justify-center align-center">
                        <button className="boton-cancelar" onClick={() => setModalAdvertencia(false)}>
                            {languageJson[language].SignUp.Step4.modalAdvertenciaBotonCancelar}
                        </button>
                        <button className="boton-continuar boton-comenzar" onClick={() => pagoModal()}>
                            {languageJson[language].SignUp.Step4.modalAdvertenciaBotonComenzar}
                        </button>
                    </div>
                </div>
            </GeneralModal>
            <GeneralModal modalIsOpen={modalPago} setIsOpen={setModalPago} contentLabel={"Cuenta creada"}>
                <div className="modal-cuenta-creada">
                    <div className="fila-titulo">
                        <p className="titulo">{languageJson[language].SignUp.Step4.modalPagoTitulo}</p>
                    </div>
                    <div className="direction-column justify-center align-center">
                        {/* <div className="boton-coinpayments direction-row justify-center align-center" onClick={() => pagoCoinpayments()}>
                    <img src="/img/coinpayments.png" className="coinpayments-img" alt="coin"></img>
                    <p>Coinpayments</p>
                </div> */}
                        <div style={{ margin: "20px", zIndex: 100 }}>
                            <stripe-buy-button
                                buy-button-id={opcionPago === 1 ? STRIPE_LINK_REGULAR : STRIPE_LINK_EXPRESS}
                                publishable-key={STRIPE_KEY}
                                client-reference-id={idPaymentStripe}
                            >
                            </stripe-buy-button>
                        </div>
                        <div style={{ width: "288px", margin: "20px" }}>
                            <CheckoutPaypal description={(opcionPago === 1) ? "Verificación regular" : "Verificación express"} amount={(opcionPago === 1) ? 67 : 77} GC={GC} />
                        </div>
                    </div>
                </div>
            </GeneralModal>
            <GeneralModal modalIsOpen={modalError} setIsOpen={setModalError} contentLabel={"Cuenta creada"}>
                <div className="modal-cuenta-creada">
                    <div className="fila-titulo">
                        <p className="titulo">{languageJson[language].SignUp.Step4.modalErrorTitulo}</p>
                    </div>
                    <div>
                        <p className="titulo">{languageJson[language].SignUp.Step4.modalErrorTexto}</p>
                    </div>
                </div>
            </GeneralModal>
        </div>


    );
}

export default Step4;