export const ENCRIPTION_KEY = process.env.REACT_APP_ENCRPYTION_KEY

export const ENCRIPTION_KEY_CAB_CAPITAL = process.env.REACT_APP_ENCRPYTION_KEY_CAB_CAPITAL
export const ENCRIPTION_KEY_2_CAB_CAPITAL = process.env.REACT_APP_ENCRPYTION_KEY_2_CAB_CAPITAL

export const CLIENT_ID_PAYPAL = process.env.REACT_APP_CLIENT_ID_PAYPAL

export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
export const STRIPE_LINK_REGULAR = process.env.REACT_APP_STRIPE_LINK_REGULAR
export const STRIPE_LINK_EXPRESS = process.env.REACT_APP_STRIPE_LINK_EXPRESS

export const URL_API = process.env.REACT_APP_DEBUG === "true" ? process.env.REACT_APP_URL_API_LOCAL : process.env.REACT_APP_URL_API_PRODUCTION
export const WS_API = process.env.REACT_APP_DEBUG === "true" ? process.env.REACT_APP_WS_API_LOCAL : process.env.REACT_APP_WS_API_PRODUCTION
