import React from 'react';

const EEUU = ({ language }) => {
  const renderSvgByCategory = (language) => {
    switch (language) {
      case 'ES':
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="none">
                <g opacity="0.5" clipPath="url(#clip0_1857_19555)">
                    <path d="M23.2258 4.25806H12.3871L0.421875 12.539V19.4218H23.2258L24 18.9677V17.5299L23.4961 17.0508V15.6914L24 15.3179V13.1059L23.5469 12.6718V11.2812L24 10.8939V8.68198L23.5469 8.28124V7.06249L24 6.47004V5.03224C24 4.60465 23.6534 4.25806 23.2258 4.25806Z" fill="#E5646E"/>
                    <path d="M12.0146 6.47021H23.999V8.6822H12.0146V6.47021Z" fill="#F2F2F6"/>
                    <path d="M12 10.894L12.3871 13.106H24V10.894H12Z" fill="#F2F2F6"/>
                    <path d="M0.774188 16.6452V12.7031L0 13.106V15.318L0.328125 15.5977V17.0508L0 17.5299V18.9677C0 19.3953 0.346641 19.7419 0.774188 19.7419H23.2258C23.6534 19.7419 24 19.3953 24 18.9677H3.0968C1.81406 18.9677 0.774188 17.9279 0.774188 16.6452Z" fill="#DB4655"/>
                    <path d="M12.3871 4.25806H0.774188L0.28125 5.03224L0.774188 13.1059H12.3871V4.25806Z" fill="#5055A0"/>
                    <path d="M0.774188 4.25806C0.346641 4.25806 0 4.60465 0 5.03224V13.1059H0.774188V4.25806Z" fill="#3F3F8F"/>
                    <path d="M1.55303 5.9839C1.33905 5.9839 1.16406 5.81056 1.16406 5.59681C1.16406 5.38306 1.3353 5.20972 1.54928 5.20972H1.55308C1.76706 5.20972 1.94017 5.38306 1.94017 5.59681C1.94017 5.81056 1.76697 5.9839 1.55303 5.9839Z" fill="white"/>
                    <path d="M1.55303 9.08083C1.33905 9.08083 1.16406 8.90748 1.16406 8.69373C1.16406 8.47998 1.3353 8.30664 1.54928 8.30664H1.55308C1.76706 8.30664 1.94017 8.47998 1.94017 8.69373C1.94017 8.90748 1.76697 9.08083 1.55303 9.08083Z" fill="white"/>
                    <path d="M1.55303 12.1775C1.33905 12.1775 1.16406 12.0042 1.16406 11.7904C1.16406 11.5767 1.3353 11.4033 1.54928 11.4033H1.55308C1.76706 11.4033 1.94017 11.5767 1.94017 11.7904C1.94017 12.0042 1.76697 12.1775 1.55303 12.1775Z" fill="white"/>
                    <path d="M1.55303 7.53224C1.33905 7.53224 1.16406 7.3589 1.16406 7.14515C1.16406 6.9314 1.3353 6.75806 1.54928 6.75806H1.55308C1.76706 6.75806 1.94017 6.9314 1.94017 7.14515C1.94013 7.3589 1.76697 7.53224 1.55303 7.53224Z" fill="#F2F2F6"/>
                    <path d="M1.55303 10.6292C1.33905 10.6292 1.16406 10.4558 1.16406 10.2421C1.16406 10.0283 1.3353 9.85498 1.54928 9.85498H1.55308C1.76706 9.85498 1.94017 10.0283 1.94017 10.2421C1.94017 10.4558 1.76697 10.6292 1.55303 10.6292Z" fill="#F2F2F6"/>
                    <path d="M10.8431 5.9839C10.6291 5.9839 10.4541 5.81056 10.4541 5.59681C10.4541 5.38306 10.6253 5.20972 10.8393 5.20972H10.8431C11.0571 5.20972 11.2302 5.38306 11.2302 5.59681C11.2302 5.81056 11.0571 5.9839 10.8431 5.9839Z" fill="white"/>
                    <path d="M10.8431 9.08083C10.6291 9.08083 10.4541 8.90748 10.4541 8.69373C10.4541 8.47998 10.6253 8.30664 10.8393 8.30664H10.8431C11.0571 8.30664 11.2302 8.47998 11.2302 8.69373C11.2302 8.90748 11.0571 9.08083 10.8431 9.08083Z" fill="white"/>
                    <path d="M10.8431 12.1775C10.6291 12.1775 10.4541 12.0042 10.4541 11.7904C10.4541 11.5767 10.6253 11.4033 10.8393 11.4033H10.8431C11.0571 11.4033 11.2302 11.5767 11.2302 11.7904C11.2302 12.0042 11.0571 12.1775 10.8431 12.1775Z" fill="white"/>
                    <path d="M10.8421 7.53224C10.6281 7.53224 10.4531 7.3589 10.4531 7.14515C10.4531 6.9314 10.6244 6.75806 10.8383 6.75806H10.8421C11.0561 6.75806 11.2292 6.9314 11.2292 7.14515C11.2292 7.3589 11.0561 7.53224 10.8421 7.53224Z" fill="#F2F2F6"/>
                    <path d="M10.8421 10.6292C10.6281 10.6292 10.4531 10.4558 10.4531 10.2421C10.4531 10.0283 10.6244 9.85498 10.8383 9.85498H10.8421C11.0561 9.85498 11.2292 10.0283 11.2292 10.2421C11.2292 10.4558 11.0561 10.6292 10.8421 10.6292Z" fill="#F2F2F6"/>
                    <path d="M3.8753 5.98415C3.66131 5.98415 3.48633 5.8108 3.48633 5.59705C3.48633 5.3833 3.65756 5.20996 3.87155 5.20996H3.87534C4.08933 5.20996 4.26244 5.3833 4.26244 5.59705C4.26244 5.8108 4.08928 5.98415 3.8753 5.98415Z" fill="#F2F2F6"/>
                    <path d="M3.8753 9.08083C3.66131 9.08083 3.48633 8.90748 3.48633 8.69373C3.48633 8.47998 3.65756 8.30664 3.87155 8.30664H3.87534C4.08933 8.30664 4.26244 8.47998 4.26244 8.69373C4.26244 8.90748 4.08928 9.08083 3.8753 9.08083Z" fill="#F2F2F6"/>
                    <path d="M3.8753 12.1778C3.66131 12.1778 3.48633 12.0044 3.48633 11.7907C3.48633 11.5769 3.65756 11.4036 3.87155 11.4036H3.87534C4.08933 11.4036 4.26244 11.5769 4.26244 11.7907C4.26244 12.0044 4.08928 12.1778 3.8753 12.1778Z" fill="#F2F2F6"/>
                    <path d="M3.87432 7.53224C3.66034 7.53224 3.48535 7.3589 3.48535 7.14515C3.48535 6.9314 3.65659 6.75806 3.87057 6.75806H3.87437C4.08835 6.75806 4.26146 6.9314 4.26146 7.14515C4.26141 7.3589 4.0883 7.53224 3.87432 7.53224Z" fill="white"/>
                    <path d="M3.87432 10.6292C3.66034 10.6292 3.48535 10.4558 3.48535 10.2421C3.48535 10.0283 3.65659 9.85498 3.87057 9.85498H3.87437C4.08835 9.85498 4.26146 10.0283 4.26146 10.2421C4.26146 10.4558 4.0883 10.6292 3.87432 10.6292Z" fill="white"/>
                    <path d="M2.71319 6.75807C2.4992 6.75807 2.32422 6.58473 2.32422 6.37098C2.32422 6.15723 2.49545 5.98389 2.70944 5.98389H2.71323C2.92722 5.98389 3.10033 6.15723 3.10033 6.37098C3.10028 6.58478 2.92717 6.75807 2.71319 6.75807Z" fill="white"/>
                    <path d="M2.71319 9.855C2.4992 9.855 2.32422 9.68165 2.32422 9.4679C2.32422 9.25415 2.49545 9.08081 2.70944 9.08081H2.71323C2.92722 9.08081 3.10033 9.25415 3.10033 9.4679C3.10033 9.68165 2.92717 9.855 2.71319 9.855Z" fill="white"/>
                    <path d="M2.71319 8.30641C2.4992 8.30641 2.32422 8.13307 2.32422 7.91932C2.32422 7.70557 2.49545 7.53223 2.70944 7.53223H2.71323C2.92722 7.53223 3.10033 7.70557 3.10033 7.91932C3.10033 8.13307 2.92717 8.30641 2.71319 8.30641Z" fill="#F2F2F6"/>
                    <path d="M2.71319 11.4036C2.4992 11.4036 2.32422 11.2302 2.32422 11.0165C2.32422 10.8027 2.49545 10.6294 2.70944 10.6294H2.71323C2.92722 10.6294 3.10033 10.8027 3.10033 11.0165C3.10033 11.2302 2.92717 11.4036 2.71319 11.4036Z" fill="#F2F2F6"/>
                    <path d="M6.19756 5.9839C5.98358 5.9839 5.80859 5.81056 5.80859 5.59681C5.80859 5.38306 5.97983 5.20972 6.19381 5.20972H6.19761C6.41159 5.20972 6.5847 5.38306 6.5847 5.59681C6.5847 5.81056 6.4115 5.9839 6.19756 5.9839Z" fill="white"/>
                    <path d="M6.19756 9.08083C5.98358 9.08083 5.80859 8.90748 5.80859 8.69373C5.80859 8.47998 5.97983 8.30664 6.19381 8.30664H6.19761C6.41159 8.30664 6.5847 8.47998 6.5847 8.69373C6.5847 8.90748 6.4115 9.08083 6.19756 9.08083Z" fill="white"/>
                    <path d="M6.19756 12.1775C5.98358 12.1775 5.80859 12.0042 5.80859 11.7904C5.80859 11.5767 5.97983 11.4033 6.19381 11.4033H6.19761C6.41159 11.4033 6.5847 11.5767 6.5847 11.7904C6.5847 12.0042 6.4115 12.1775 6.19756 12.1775Z" fill="white"/>
                    <path d="M6.19756 7.53224C5.98358 7.53224 5.80859 7.3589 5.80859 7.14515C5.80859 6.9314 5.97983 6.75806 6.19381 6.75806H6.19761C6.41159 6.75806 6.5847 6.9314 6.5847 7.14515C6.58466 7.3589 6.4115 7.53224 6.19756 7.53224Z" fill="#F2F2F6"/>
                    <path d="M6.19756 10.6292C5.98358 10.6292 5.80859 10.4558 5.80859 10.2421C5.80859 10.0283 5.97983 9.85498 6.19381 9.85498H6.19761C6.41159 9.85498 6.5847 10.0283 6.5847 10.2421C6.5847 10.4558 6.4115 10.6292 6.19756 10.6292Z" fill="#F2F2F6"/>
                    <path d="M5.03643 6.75807C4.82245 6.75807 4.64746 6.58473 4.64746 6.37098C4.64746 6.15723 4.8187 5.98389 5.03268 5.98389H5.03648C5.25046 5.98389 5.42357 6.15723 5.42357 6.37098C5.42357 6.58478 5.25041 6.75807 5.03643 6.75807Z" fill="#F2F2F6"/>
                    <path d="M5.03643 9.855C4.82245 9.855 4.64746 9.68165 4.64746 9.4679C4.64746 9.25415 4.8187 9.08081 5.03268 9.08081H5.03648C5.25046 9.08081 5.42357 9.25415 5.42357 9.4679C5.42357 9.68165 5.25041 9.855 5.03643 9.855Z" fill="#F2F2F6"/>
                    <path d="M5.03643 8.30641C4.82245 8.30641 4.64746 8.13307 4.64746 7.91932C4.64746 7.70557 4.8187 7.53223 5.03268 7.53223H5.03648C5.25046 7.53223 5.42357 7.70557 5.42357 7.91932C5.42357 8.13307 5.25041 8.30641 5.03643 8.30641Z" fill="white"/>
                    <path d="M5.03643 11.4036C4.82245 11.4036 4.64746 11.2302 4.64746 11.0165C4.64746 10.8027 4.8187 10.6294 5.03268 10.6294H5.03648C5.25046 10.6294 5.42357 10.8027 5.42357 11.0165C5.42357 11.2302 5.25041 11.4036 5.03643 11.4036Z" fill="white"/>
                    <path d="M8.51592 5.9839C8.30194 5.9839 8.12695 5.81056 8.12695 5.59681C8.12695 5.38306 8.29819 5.20972 8.51217 5.20972H8.51597C8.72995 5.20972 8.90306 5.38306 8.90306 5.59681C8.90306 5.81056 8.72991 5.9839 8.51592 5.9839Z" fill="#F2F2F6"/>
                    <path d="M8.51592 9.08083C8.30194 9.08083 8.12695 8.90748 8.12695 8.69373C8.12695 8.47998 8.29819 8.30664 8.51217 8.30664H8.51597C8.72995 8.30664 8.90306 8.47998 8.90306 8.69373C8.90306 8.90748 8.72991 9.08083 8.51592 9.08083Z" fill="#F2F2F6"/>
                    <path d="M8.51592 12.1775C8.30194 12.1775 8.12695 12.0042 8.12695 11.7904C8.12695 11.5767 8.29819 11.4033 8.51217 11.4033H8.51597C8.72995 11.4033 8.90306 11.5767 8.90306 11.7904C8.90306 12.0042 8.72991 12.1775 8.51592 12.1775Z" fill="#F2F2F6"/>
                    <path d="M8.51592 7.53224C8.30194 7.53224 8.12695 7.3589 8.12695 7.14515C8.12695 6.9314 8.29819 6.75806 8.51217 6.75806H8.51597C8.72995 6.75806 8.90306 6.9314 8.90306 7.14515C8.90302 7.3589 8.72991 7.53224 8.51592 7.53224Z" fill="white"/>
                    <path d="M8.51592 10.6292C8.30194 10.6292 8.12695 10.4558 8.12695 10.2421C8.12695 10.0283 8.29819 9.85498 8.51217 9.85498H8.51597C8.72995 9.85498 8.90306 10.0283 8.90306 10.2421C8.90306 10.4558 8.72991 10.6292 8.51592 10.6292Z" fill="white"/>
                    <path d="M7.35479 6.75807C7.1408 6.75807 6.96582 6.58473 6.96582 6.37098C6.96582 6.15723 7.13705 5.98389 7.35104 5.98389H7.35484C7.56882 5.98389 7.74193 6.15723 7.74193 6.37098C7.74188 6.58478 7.56877 6.75807 7.35479 6.75807Z" fill="white"/>
                    <path d="M7.35479 9.855C7.1408 9.855 6.96582 9.68165 6.96582 9.4679C6.96582 9.25415 7.13705 9.08081 7.35104 9.08081H7.35484C7.56882 9.08081 7.74193 9.25415 7.74193 9.4679C7.74193 9.68165 7.56877 9.855 7.35479 9.855Z" fill="white"/>
                    <path d="M7.35479 8.30641C7.1408 8.30641 6.96582 8.13307 6.96582 7.91932C6.96582 7.70557 7.13705 7.53223 7.35104 7.53223H7.35484C7.56882 7.53223 7.74193 7.70557 7.74193 7.91932C7.74193 8.13307 7.56877 8.30641 7.35479 8.30641Z" fill="#F2F2F6"/>
                    <path d="M7.35479 11.4036C7.1408 11.4036 6.96582 11.2302 6.96582 11.0165C6.96582 10.8027 7.13705 10.6294 7.35104 10.6294H7.35484C7.56882 10.6294 7.74193 10.8027 7.74193 11.0165C7.74193 11.2302 7.56877 11.4036 7.35479 11.4036Z" fill="#F2F2F6"/>
                    <path d="M9.68096 6.75807C9.46698 6.75807 9.29199 6.58473 9.29199 6.37098C9.29199 6.15723 9.46323 5.98389 9.67721 5.98389H9.68101C9.89499 5.98389 10.0681 6.15723 10.0681 6.37098C10.0681 6.58478 9.89495 6.75807 9.68096 6.75807Z" fill="#F2F2F6"/>
                    <path d="M9.68096 9.855C9.46698 9.855 9.29199 9.68165 9.29199 9.4679C9.29199 9.25415 9.46323 9.08081 9.67721 9.08081H9.68101C9.89499 9.08081 10.0681 9.25415 10.0681 9.4679C10.0681 9.68165 9.89495 9.855 9.68096 9.855Z" fill="#F2F2F6"/>
                    <path d="M9.68096 8.30641C9.46698 8.30641 9.29199 8.13307 9.29199 7.91932C9.29199 7.70557 9.46323 7.53223 9.67721 7.53223H9.68101C9.89499 7.53223 10.0681 7.70557 10.0681 7.91932C10.0681 8.13307 9.89495 8.30641 9.68096 8.30641Z" fill="white"/>
                    <path d="M9.68096 11.4036C9.46698 11.4036 9.29199 11.2302 9.29199 11.0165C9.29199 10.8027 9.46323 10.6294 9.67721 10.6294H9.68101C9.89499 10.6294 10.0681 10.8027 10.0681 11.0165C10.0681 11.2302 9.89495 11.4036 9.68096 11.4036Z" fill="white"/>
                    <path d="M0.140625 16.8905L0.949687 17.5299H24V15.3179H0.774188L0.140625 16.8905Z" fill="#F2F2F6"/>
                    <path d="M0 15.3179V17.5299H0.949687C0.837141 17.2571 0.774234 16.9585 0.774234 16.6451V15.3179L0 15.3179Z" fill="#E1E1E6"/>
                </g>
                <defs>
                    <clipPath id="clip0_1857_19555">
                        <rect width="45" height="45" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        );
      case 'EN':
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_1857_19555)">
                    <path d="M23.2258 4.25806H12.3871L0.421875 12.539V19.4218H23.2258L24 18.9677V17.5299L23.4961 17.0508V15.6914L24 15.3179V13.1059L23.5469 12.6718V11.2812L24 10.8939V8.68198L23.5469 8.28124V7.06249L24 6.47004V5.03224C24 4.60465 23.6534 4.25806 23.2258 4.25806Z" fill="#E5646E"/>
                    <path d="M12.0146 6.47021H23.999V8.6822H12.0146V6.47021Z" fill="#F2F2F6"/>
                    <path d="M12 10.894L12.3871 13.106H24V10.894H12Z" fill="#F2F2F6"/>
                    <path d="M0.774188 16.6452V12.7031L0 13.106V15.318L0.328125 15.5977V17.0508L0 17.5299V18.9677C0 19.3953 0.346641 19.7419 0.774188 19.7419H23.2258C23.6534 19.7419 24 19.3953 24 18.9677H3.0968C1.81406 18.9677 0.774188 17.9279 0.774188 16.6452Z" fill="#DB4655"/>
                    <path d="M12.3871 4.25806H0.774188L0.28125 5.03224L0.774188 13.1059H12.3871V4.25806Z" fill="#5055A0"/>
                    <path d="M0.774188 4.25806C0.346641 4.25806 0 4.60465 0 5.03224V13.1059H0.774188V4.25806Z" fill="#3F3F8F"/>
                    <path d="M1.55303 5.9839C1.33905 5.9839 1.16406 5.81056 1.16406 5.59681C1.16406 5.38306 1.3353 5.20972 1.54928 5.20972H1.55308C1.76706 5.20972 1.94017 5.38306 1.94017 5.59681C1.94017 5.81056 1.76697 5.9839 1.55303 5.9839Z" fill="white"/>
                    <path d="M1.55303 9.08083C1.33905 9.08083 1.16406 8.90748 1.16406 8.69373C1.16406 8.47998 1.3353 8.30664 1.54928 8.30664H1.55308C1.76706 8.30664 1.94017 8.47998 1.94017 8.69373C1.94017 8.90748 1.76697 9.08083 1.55303 9.08083Z" fill="white"/>
                    <path d="M1.55303 12.1775C1.33905 12.1775 1.16406 12.0042 1.16406 11.7904C1.16406 11.5767 1.3353 11.4033 1.54928 11.4033H1.55308C1.76706 11.4033 1.94017 11.5767 1.94017 11.7904C1.94017 12.0042 1.76697 12.1775 1.55303 12.1775Z" fill="white"/>
                    <path d="M1.55303 7.53224C1.33905 7.53224 1.16406 7.3589 1.16406 7.14515C1.16406 6.9314 1.3353 6.75806 1.54928 6.75806H1.55308C1.76706 6.75806 1.94017 6.9314 1.94017 7.14515C1.94013 7.3589 1.76697 7.53224 1.55303 7.53224Z" fill="#F2F2F6"/>
                    <path d="M1.55303 10.6292C1.33905 10.6292 1.16406 10.4558 1.16406 10.2421C1.16406 10.0283 1.3353 9.85498 1.54928 9.85498H1.55308C1.76706 9.85498 1.94017 10.0283 1.94017 10.2421C1.94017 10.4558 1.76697 10.6292 1.55303 10.6292Z" fill="#F2F2F6"/>
                    <path d="M10.8431 5.9839C10.6291 5.9839 10.4541 5.81056 10.4541 5.59681C10.4541 5.38306 10.6253 5.20972 10.8393 5.20972H10.8431C11.0571 5.20972 11.2302 5.38306 11.2302 5.59681C11.2302 5.81056 11.0571 5.9839 10.8431 5.9839Z" fill="white"/>
                    <path d="M10.8431 9.08083C10.6291 9.08083 10.4541 8.90748 10.4541 8.69373C10.4541 8.47998 10.6253 8.30664 10.8393 8.30664H10.8431C11.0571 8.30664 11.2302 8.47998 11.2302 8.69373C11.2302 8.90748 11.0571 9.08083 10.8431 9.08083Z" fill="white"/>
                    <path d="M10.8431 12.1775C10.6291 12.1775 10.4541 12.0042 10.4541 11.7904C10.4541 11.5767 10.6253 11.4033 10.8393 11.4033H10.8431C11.0571 11.4033 11.2302 11.5767 11.2302 11.7904C11.2302 12.0042 11.0571 12.1775 10.8431 12.1775Z" fill="white"/>
                    <path d="M10.8421 7.53224C10.6281 7.53224 10.4531 7.3589 10.4531 7.14515C10.4531 6.9314 10.6244 6.75806 10.8383 6.75806H10.8421C11.0561 6.75806 11.2292 6.9314 11.2292 7.14515C11.2292 7.3589 11.0561 7.53224 10.8421 7.53224Z" fill="#F2F2F6"/>
                    <path d="M10.8421 10.6292C10.6281 10.6292 10.4531 10.4558 10.4531 10.2421C10.4531 10.0283 10.6244 9.85498 10.8383 9.85498H10.8421C11.0561 9.85498 11.2292 10.0283 11.2292 10.2421C11.2292 10.4558 11.0561 10.6292 10.8421 10.6292Z" fill="#F2F2F6"/>
                    <path d="M3.8753 5.98415C3.66131 5.98415 3.48633 5.8108 3.48633 5.59705C3.48633 5.3833 3.65756 5.20996 3.87155 5.20996H3.87534C4.08933 5.20996 4.26244 5.3833 4.26244 5.59705C4.26244 5.8108 4.08928 5.98415 3.8753 5.98415Z" fill="#F2F2F6"/>
                    <path d="M3.8753 9.08083C3.66131 9.08083 3.48633 8.90748 3.48633 8.69373C3.48633 8.47998 3.65756 8.30664 3.87155 8.30664H3.87534C4.08933 8.30664 4.26244 8.47998 4.26244 8.69373C4.26244 8.90748 4.08928 9.08083 3.8753 9.08083Z" fill="#F2F2F6"/>
                    <path d="M3.8753 12.1778C3.66131 12.1778 3.48633 12.0044 3.48633 11.7907C3.48633 11.5769 3.65756 11.4036 3.87155 11.4036H3.87534C4.08933 11.4036 4.26244 11.5769 4.26244 11.7907C4.26244 12.0044 4.08928 12.1778 3.8753 12.1778Z" fill="#F2F2F6"/>
                    <path d="M3.87432 7.53224C3.66034 7.53224 3.48535 7.3589 3.48535 7.14515C3.48535 6.9314 3.65659 6.75806 3.87057 6.75806H3.87437C4.08835 6.75806 4.26146 6.9314 4.26146 7.14515C4.26141 7.3589 4.0883 7.53224 3.87432 7.53224Z" fill="white"/>
                    <path d="M3.87432 10.6292C3.66034 10.6292 3.48535 10.4558 3.48535 10.2421C3.48535 10.0283 3.65659 9.85498 3.87057 9.85498H3.87437C4.08835 9.85498 4.26146 10.0283 4.26146 10.2421C4.26146 10.4558 4.0883 10.6292 3.87432 10.6292Z" fill="white"/>
                    <path d="M2.71319 6.75807C2.4992 6.75807 2.32422 6.58473 2.32422 6.37098C2.32422 6.15723 2.49545 5.98389 2.70944 5.98389H2.71323C2.92722 5.98389 3.10033 6.15723 3.10033 6.37098C3.10028 6.58478 2.92717 6.75807 2.71319 6.75807Z" fill="white"/>
                    <path d="M2.71319 9.855C2.4992 9.855 2.32422 9.68165 2.32422 9.4679C2.32422 9.25415 2.49545 9.08081 2.70944 9.08081H2.71323C2.92722 9.08081 3.10033 9.25415 3.10033 9.4679C3.10033 9.68165 2.92717 9.855 2.71319 9.855Z" fill="white"/>
                    <path d="M2.71319 8.30641C2.4992 8.30641 2.32422 8.13307 2.32422 7.91932C2.32422 7.70557 2.49545 7.53223 2.70944 7.53223H2.71323C2.92722 7.53223 3.10033 7.70557 3.10033 7.91932C3.10033 8.13307 2.92717 8.30641 2.71319 8.30641Z" fill="#F2F2F6"/>
                    <path d="M2.71319 11.4036C2.4992 11.4036 2.32422 11.2302 2.32422 11.0165C2.32422 10.8027 2.49545 10.6294 2.70944 10.6294H2.71323C2.92722 10.6294 3.10033 10.8027 3.10033 11.0165C3.10033 11.2302 2.92717 11.4036 2.71319 11.4036Z" fill="#F2F2F6"/>
                    <path d="M6.19756 5.9839C5.98358 5.9839 5.80859 5.81056 5.80859 5.59681C5.80859 5.38306 5.97983 5.20972 6.19381 5.20972H6.19761C6.41159 5.20972 6.5847 5.38306 6.5847 5.59681C6.5847 5.81056 6.4115 5.9839 6.19756 5.9839Z" fill="white"/>
                    <path d="M6.19756 9.08083C5.98358 9.08083 5.80859 8.90748 5.80859 8.69373C5.80859 8.47998 5.97983 8.30664 6.19381 8.30664H6.19761C6.41159 8.30664 6.5847 8.47998 6.5847 8.69373C6.5847 8.90748 6.4115 9.08083 6.19756 9.08083Z" fill="white"/>
                    <path d="M6.19756 12.1775C5.98358 12.1775 5.80859 12.0042 5.80859 11.7904C5.80859 11.5767 5.97983 11.4033 6.19381 11.4033H6.19761C6.41159 11.4033 6.5847 11.5767 6.5847 11.7904C6.5847 12.0042 6.4115 12.1775 6.19756 12.1775Z" fill="white"/>
                    <path d="M6.19756 7.53224C5.98358 7.53224 5.80859 7.3589 5.80859 7.14515C5.80859 6.9314 5.97983 6.75806 6.19381 6.75806H6.19761C6.41159 6.75806 6.5847 6.9314 6.5847 7.14515C6.58466 7.3589 6.4115 7.53224 6.19756 7.53224Z" fill="#F2F2F6"/>
                    <path d="M6.19756 10.6292C5.98358 10.6292 5.80859 10.4558 5.80859 10.2421C5.80859 10.0283 5.97983 9.85498 6.19381 9.85498H6.19761C6.41159 9.85498 6.5847 10.0283 6.5847 10.2421C6.5847 10.4558 6.4115 10.6292 6.19756 10.6292Z" fill="#F2F2F6"/>
                    <path d="M5.03643 6.75807C4.82245 6.75807 4.64746 6.58473 4.64746 6.37098C4.64746 6.15723 4.8187 5.98389 5.03268 5.98389H5.03648C5.25046 5.98389 5.42357 6.15723 5.42357 6.37098C5.42357 6.58478 5.25041 6.75807 5.03643 6.75807Z" fill="#F2F2F6"/>
                    <path d="M5.03643 9.855C4.82245 9.855 4.64746 9.68165 4.64746 9.4679C4.64746 9.25415 4.8187 9.08081 5.03268 9.08081H5.03648C5.25046 9.08081 5.42357 9.25415 5.42357 9.4679C5.42357 9.68165 5.25041 9.855 5.03643 9.855Z" fill="#F2F2F6"/>
                    <path d="M5.03643 8.30641C4.82245 8.30641 4.64746 8.13307 4.64746 7.91932C4.64746 7.70557 4.8187 7.53223 5.03268 7.53223H5.03648C5.25046 7.53223 5.42357 7.70557 5.42357 7.91932C5.42357 8.13307 5.25041 8.30641 5.03643 8.30641Z" fill="white"/>
                    <path d="M5.03643 11.4036C4.82245 11.4036 4.64746 11.2302 4.64746 11.0165C4.64746 10.8027 4.8187 10.6294 5.03268 10.6294H5.03648C5.25046 10.6294 5.42357 10.8027 5.42357 11.0165C5.42357 11.2302 5.25041 11.4036 5.03643 11.4036Z" fill="white"/>
                    <path d="M8.51592 5.9839C8.30194 5.9839 8.12695 5.81056 8.12695 5.59681C8.12695 5.38306 8.29819 5.20972 8.51217 5.20972H8.51597C8.72995 5.20972 8.90306 5.38306 8.90306 5.59681C8.90306 5.81056 8.72991 5.9839 8.51592 5.9839Z" fill="#F2F2F6"/>
                    <path d="M8.51592 9.08083C8.30194 9.08083 8.12695 8.90748 8.12695 8.69373C8.12695 8.47998 8.29819 8.30664 8.51217 8.30664H8.51597C8.72995 8.30664 8.90306 8.47998 8.90306 8.69373C8.90306 8.90748 8.72991 9.08083 8.51592 9.08083Z" fill="#F2F2F6"/>
                    <path d="M8.51592 12.1775C8.30194 12.1775 8.12695 12.0042 8.12695 11.7904C8.12695 11.5767 8.29819 11.4033 8.51217 11.4033H8.51597C8.72995 11.4033 8.90306 11.5767 8.90306 11.7904C8.90306 12.0042 8.72991 12.1775 8.51592 12.1775Z" fill="#F2F2F6"/>
                    <path d="M8.51592 7.53224C8.30194 7.53224 8.12695 7.3589 8.12695 7.14515C8.12695 6.9314 8.29819 6.75806 8.51217 6.75806H8.51597C8.72995 6.75806 8.90306 6.9314 8.90306 7.14515C8.90302 7.3589 8.72991 7.53224 8.51592 7.53224Z" fill="white"/>
                    <path d="M8.51592 10.6292C8.30194 10.6292 8.12695 10.4558 8.12695 10.2421C8.12695 10.0283 8.29819 9.85498 8.51217 9.85498H8.51597C8.72995 9.85498 8.90306 10.0283 8.90306 10.2421C8.90306 10.4558 8.72991 10.6292 8.51592 10.6292Z" fill="white"/>
                    <path d="M7.35479 6.75807C7.1408 6.75807 6.96582 6.58473 6.96582 6.37098C6.96582 6.15723 7.13705 5.98389 7.35104 5.98389H7.35484C7.56882 5.98389 7.74193 6.15723 7.74193 6.37098C7.74188 6.58478 7.56877 6.75807 7.35479 6.75807Z" fill="white"/>
                    <path d="M7.35479 9.855C7.1408 9.855 6.96582 9.68165 6.96582 9.4679C6.96582 9.25415 7.13705 9.08081 7.35104 9.08081H7.35484C7.56882 9.08081 7.74193 9.25415 7.74193 9.4679C7.74193 9.68165 7.56877 9.855 7.35479 9.855Z" fill="white"/>
                    <path d="M7.35479 8.30641C7.1408 8.30641 6.96582 8.13307 6.96582 7.91932C6.96582 7.70557 7.13705 7.53223 7.35104 7.53223H7.35484C7.56882 7.53223 7.74193 7.70557 7.74193 7.91932C7.74193 8.13307 7.56877 8.30641 7.35479 8.30641Z" fill="#F2F2F6"/>
                    <path d="M7.35479 11.4036C7.1408 11.4036 6.96582 11.2302 6.96582 11.0165C6.96582 10.8027 7.13705 10.6294 7.35104 10.6294H7.35484C7.56882 10.6294 7.74193 10.8027 7.74193 11.0165C7.74193 11.2302 7.56877 11.4036 7.35479 11.4036Z" fill="#F2F2F6"/>
                    <path d="M9.68096 6.75807C9.46698 6.75807 9.29199 6.58473 9.29199 6.37098C9.29199 6.15723 9.46323 5.98389 9.67721 5.98389H9.68101C9.89499 5.98389 10.0681 6.15723 10.0681 6.37098C10.0681 6.58478 9.89495 6.75807 9.68096 6.75807Z" fill="#F2F2F6"/>
                    <path d="M9.68096 9.855C9.46698 9.855 9.29199 9.68165 9.29199 9.4679C9.29199 9.25415 9.46323 9.08081 9.67721 9.08081H9.68101C9.89499 9.08081 10.0681 9.25415 10.0681 9.4679C10.0681 9.68165 9.89495 9.855 9.68096 9.855Z" fill="#F2F2F6"/>
                    <path d="M9.68096 8.30641C9.46698 8.30641 9.29199 8.13307 9.29199 7.91932C9.29199 7.70557 9.46323 7.53223 9.67721 7.53223H9.68101C9.89499 7.53223 10.0681 7.70557 10.0681 7.91932C10.0681 8.13307 9.89495 8.30641 9.68096 8.30641Z" fill="white"/>
                    <path d="M9.68096 11.4036C9.46698 11.4036 9.29199 11.2302 9.29199 11.0165C9.29199 10.8027 9.46323 10.6294 9.67721 10.6294H9.68101C9.89499 10.6294 10.0681 10.8027 10.0681 11.0165C10.0681 11.2302 9.89495 11.4036 9.68096 11.4036Z" fill="white"/>
                    <path d="M0.140625 16.8905L0.949687 17.5299H24V15.3179H0.774188L0.140625 16.8905Z" fill="#F2F2F6"/>
                    <path d="M0 15.3179V17.5299H0.949687C0.837141 17.2571 0.774234 16.9585 0.774234 16.6451V15.3179L0 15.3179Z" fill="#E1E1E6"/>
                </g>
                <defs>
                    <clipPath id="clip0_1857_19555">
                        <rect width="45" height="45" fill="white"/>
                    </clipPath>
                </defs>
            </svg>
        );
      default:
        return null;
    }
  };

  return (
      renderSvgByCategory(language)
  );
};

export default EEUU;
