import React from "react";
import './style.scss'

const Loader = () => {
  return (
    <div className="loader">
      <img className="imgLogo" src="/img/logo-cab-risk.png" alt="Logo" />
    </div>
  );
};

export default Loader;
