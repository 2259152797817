import React, {useEffect, useState, useContext } from 'react'

import "./assets/FormKYC.scss"

//Imports librerias externas
import { toast, Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';


//Imports componentes auxiliares
import GeneralContext from '../../context/GeneralContext'
import { eliminarSesion,obtenerSesion } from '../../Utils/Utils'
import Loader from '../../Utils/loader/loader';
import EEUU from '../../Utils/Flags/EEUU';
import SPAIN from '../../Utils/Flags/SPAIN';

//Componentes hijos
import Flow from './Flow';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';


const FormKYC = () => {
    //Context
    const GC = useContext(GeneralContext);
    const { user, modificarUser, isLoading, language, modificarLanguaje} = GC;

    const navigate = useNavigate();

    const [seccion, setSeccion] = useState({1: "Activo", 2: "Sin iniciar", 3: "Sin iniciar", 4: "Sin iniciar", 5: "Sin iniciar", 6: "Sin iniciar"})
    
    const logOut = async() => {
        modificarUser(null);
        eliminarSesion();
        navigate("/");
    }

    function renderSeccion() {
        const componenteActivo = Object.keys(seccion).find(key => seccion[key] === "Activo");
        switch (componenteActivo) {
          case "1": return <Step1 GC = {GC} seccion = {seccion} setSeccion = {setSeccion} toast = {toast}/>;
          case "2": return <Step2 GC = {GC} seccion = {seccion} setSeccion = {setSeccion} toast = {toast}/>;
          case "3": return <Step3 GC = {GC} seccion = {seccion} setSeccion = {setSeccion} toast = {toast}/>;
          case "4": return <Step4 GC = {GC} seccion = {seccion} setSeccion = {setSeccion} toast = {toast}/>;
          case "5": return <Step5 GC = {GC} seccion = {seccion} setSeccion = {setSeccion} toast = {toast}/>;
          case "6": return <Step6 GC = {GC} seccion = {seccion} setSeccion = {setSeccion} toast = {toast}/>;
          default: return "No hay componente activo";
        }
    }

    //Funcion auxiliar: revisa si el usuario ya realizo el pago y este esta completado
    const revisionPago = React.useCallback(() => {
        let pagoCompletado = false;
        for(const pago of user.pagos){
            if(pago.status_payment === "COMPLETED"){
                pagoCompletado = true;
            }
        }
        if(!pagoCompletado)
            navigate("/Registro");
    }, [user])

    useEffect(()=>{
        const newUser = obtenerSesion();
        if(!newUser)
            navigate("/");
        else if(user){
            if(!user?.terms_conditions || !user?.validate || !user?.pagos)
                navigate("/Registro");
            if(user?.pagos?.length > 0)
                revisionPago(user.pagos)
            if(user?.form_status !== "No enviado")
                navigate("/Resultados");
        }
        
    }, [user, revisionPago])

    return ( 
        <div className = "general-body-kyc direction-column justify-start align-center">
            <Toaster position="top-right" reverseOrder={false}/>
            { isLoading && <Loader/> }
            <header>
                <img src = "/img/Logo CAB-RISK-EMPRESA.svg" className = "img-logo" alt = "logo"></img>
                <div className = "direction-row justify-between align-center" style = {{width: 180, margin: 20}}>
                    <div onClick = {() => modificarLanguaje("ES")}><SPAIN language = {language} /></div>
                    <div onClick = {() => modificarLanguaje("EN")}><EEUU language = {language} /></div>
                    <img src = "/img/box-arrow-in-right.svg" className = "img-arrow" alt = "flecha" onClick={() => logOut()}></img>
                </div>
            </header>
            <div className = "direction-row justify-around align-start form-space"> 
                <Flow seccion = {seccion} setSeccion = {setSeccion}/>
                { renderSeccion() } 
            </div>                  
        </div>
    );
}
 
export default FormKYC;