import React, { useState, useEffect, useRef } from 'react'

//Imports librerias externas
import axios from 'axios'

//Imports componentes auxiliares
import { URL_API } from '../../Config/config'
import { guardarSesion } from '../../Utils/Utils'
import languageJson from "../../Utils/language.json";


const Step4 = ({ GC, seccion, setSeccion, toast }) => {

    const { user, modificarUser, modificarLoading, language } = GC;

    const [formulario, setFormulario] = useState({
        country_residence: "",
        province: "",
        city: "",
        postal_code: "",
        residential_address: "",
        specifications: "",
    })

    const [paises, setPaises] = useState([])
    const [boton, setBoton] = useState("boton-disabled")
    const [comprobar, setComprobar] = useState(false)

    const fileInputDocumento = useRef(null);

    const handleFileChangeDocument = (event) => {
        const file = event.target.files[0];
        if (file && file.type.includes("pdf"))
            handleUpload(file);
    };

    const handleUpload = React.useCallback(async (file) => {
        try {
            modificarLoading(true);
            const formData = new FormData();
            formData.append('userId', user._id);
            formData.append('verificacion', file);
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`
            const config = { headers: { 'Content-Type': 'multipart/form-data', } };
            const response = await axios.put(URL_API + 'api/authentication/update-verificacion', formData, config)
            if (response.data.success) {
                let newUser = response.data.user
                newUser.bearer = user.bearer
                newUser.code = user.code
                newUser.validate = true
                modificarUser(newUser)
                guardarSesion(newUser)
            } else {
                toast.error(languageJson[language].ToastMessage.error6)
            }
            modificarLoading(false);
        } catch (error) {
            console.log(error)
            toast.error(languageJson[language].ToastMessage.error3)
            modificarLoading(false);
        }
    }, [user, modificarUser, toast, modificarLoading, language]);

    const initData = React.useCallback(() => {
        setFormulario({
            country_residence: (user?.country_residence !== "N/A" ? user?.country_residence : ""),
            province: (user?.province !== "N/A" ? user?.province : ""),
            city: (user?.city !== "N/A" ? user?.city : ""),
            postal_code: (user?.postal_code !== "N/A" ? user?.postal_code : ""),
            residential_address: (user?.residential_address !== "N/A" ? user?.residential_address : ""),
            specifications: (user?.specifications !== "N/A" ? user?.specifications : "")
        })
        setComprobar(true);
    }, [user])

    const actualizarInformacionPersonal = async () => {
        if (validarAtributos(formulario)) {
            modificarLoading(true);
            try {
                axios.defaults.headers.common['Authorization'] = `Bearer ${user?.bearer}`
                console.log(user?.bearer)
                const response = await axios.put(URL_API + 'api/authentication/update-verification-residence', formulario)
                if (response.data.success) {
                    let newUser = response.data.user
                    newUser.bearer = user.bearer
                    newUser.code = user.code
                    newUser.validate = true

                    modificarUser(newUser)
                    guardarSesion(newUser)

                    setSeccion({ ...seccion, 4: "Completado", 5: "Activo" })
                } else {
                    toast.error(languageJson[language].ToastMessage.error1)
                }
            } catch (error) {
                toast.error(languageJson[language].ToastMessage.error1)
                console.log(error)
            }
            modificarLoading(false);
        } else {
            toast.error(languageJson[language].ToastMessage.error2)
        }
    }


    //Funcion auxiliar para obtener los paises.
    const obtenerPaises = React.useCallback(async () => {
        const res = await axios.get("https://restcountries.com/v3.1/all");
        const paisesOrdenados = res.data.sort((a, b) => {
            const nombreA = a.name.common.toLowerCase();
            const nombreB = b.name.common.toLowerCase();
            if (nombreA < nombreB) return -1;
            if (nombreA > nombreB) return 1;
            return 0;
        });

        setPaises(paisesOrdenados);
    }, [setPaises])

    //funcion auxiliar para validar formulario
    function validarAtributos(json) {
        for (let key in json) {
            if (json.hasOwnProperty(key) && json[key] === "") {
                return false;
            }
        }
        return true;
    }

    //Funcion auxiliar: revisar si el usuario ya realizo el diligenciamiento del formulario del primer paso.
    const comprobarDiligenciamientoFormulario = React.useCallback(() => {
        let bandera = true;
        for (let atributo in formulario) {
            if (!formulario[atributo]) {
                bandera = false;
                break;
            }
        }
        console.log((bandera && user?.document_verification) ? true : false)
        return (bandera && user?.document_verification) ? true : false;
    }, [formulario, user])


    useEffect(() => {
        if (paises.length === 0)
            obtenerPaises();
        if (!comprobar && user) {
            initData();
        }
        if (comprobarDiligenciamientoFormulario())
            setBoton("boton-enabled")
        else if (boton === "boton-enabled")
            setBoton("boton-disabled")
    }, [boton, paises, comprobar, user, formulario, comprobarDiligenciamientoFormulario, initData, obtenerPaises])

    const documentVerification = decodeURIComponent(
        user?.document_verification?.split('/')[user?.document_verification?.split('/').length - 1] ?? ''
    );

    return (
        <div style={{ color: "white", width: "82%", height: "650px", margin: 50 }} className="direction-column justify-start align-start">
            <p className="titulo-1">{languageJson[language].FormKYC.Step4.titulo1}</p>
            <div className="direction-column justify-start align-start scroll-custom" style={{ width: "100%", height: "530px", overflowY: "auto" }}>
                <div className="direction-row justify-center align-center">
                    <div className="espacio-input">
                        <p className="titulo-2">{languageJson[language].FormKYC.Step4.paisResidenciaLabel}</p>
                        <select className="input-select" value={formulario.country_residence} onChange={(e) => setFormulario({ ...formulario, country_residence: e.target.value })}>
                            <option value="">{languageJson[language].FormKYC.Step4.selecciona}</option>
                            {
                                paises.map((pais, index) => {
                                    return <option key={index} value={pais.name.common}>{pais.name.common}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="espacio-input">
                        <p className="titulo-2">{languageJson[language].FormKYC.Step4.provinciaRegionLabel}</p>
                        <input type="text" className="input" value={formulario.province} placeholder={languageJson[language].FormKYC.Step4.escribeAqui} onChange={(e) => setFormulario({ ...formulario, province: e.target.value })}></input>
                    </div>
                </div>
                <div className="direction-row justify-center align-center">
                    <div className="espacio-input">
                        <p className="titulo-2">{languageJson[language].FormKYC.Step4.ciudadLabel}</p>
                        <input type="text" className="input" value={formulario.city} placeholder={languageJson[language].FormKYC.Step4.escribeAqui} onChange={(e) => setFormulario({ ...formulario, city: e.target.value })}></input>
                    </div>
                    <div className="espacio-input">
                        <p className="titulo-2">{languageJson[language].FormKYC.Step4.codigoPostal}</p>
                        <input type="number" className="input" value={formulario.postal_code} placeholder={languageJson[language].FormKYC.Step4.placeholderCode} onChange={(e) => setFormulario({ ...formulario, postal_code: e.target.value })}></input>
                    </div>
                </div>
                <div className="direction-row justify-center align-center">
                    <div className="espacio-input">
                        <p className="titulo-2">{languageJson[language].FormKYC.Step4.direccionResidenciaLabel}</p>
                        <input type="text" className="input" value={formulario.residential_address} placeholder={languageJson[language].FormKYC.Step4.escribeAqui} onChange={(e) => setFormulario({ ...formulario, residential_address: e.target.value })}></input>
                    </div>
                    <div className="espacio-input">
                        <p className="titulo-2">{languageJson[language].FormKYC.Step4.especificaciones}</p>
                        <input type="text" className="input" value={formulario.specifications} placeholder={languageJson[language].FormKYC.Step4.especificacionesPlaceholder} onChange={(e) => setFormulario({ ...formulario, specifications: e.target.value })}></input>
                    </div>
                </div>
                <div className="direction-row justify-center align-center">
                    <div className="espacio-input">
                        <p className="titulo-2">{languageJson[language].FormKYC.Step4.documentoVerificacion}</p>
                        <span style={{ fontSize: '0.8rem' }}>Solo documentos en formato PDF son permitidos</span>
                        <span style={{ fontSize: '0.8rem' }}>
                        {languageJson[language].FormKYC.Step4.docToUpload}
                        </span>
                        <input
                            accept="application/pdf"
                            type="file"
                            ref={fileInputDocumento}
                            onChange={(e) => handleFileChangeDocument(e)}
                            style={{ display: 'none' }}
                        />
                        <label style={{ position: "relative", padding: '0.5rem 0' }}>
                            <button onClick={() => fileInputDocumento.current.click()} className="boton-documentos" style={{ marginTop: 0 }}><p style={{ marginLeft: "-45px" }}>{languageJson[language].FormKYC.Step4.adjuntarDocumento}</p></button>
                            <img src="/img/upload.svg" className="upload-img posterior" alt="" style={{ right: 45, top: 18 }}></img>
                        </label>
                        <label>{documentVerification}</label>
                        <span style={{ fontSize: '0.8rem', marginTop: '8px' }}>
                            <span style={{ fontSize: '0.9rem', color: '#ab5046' }}>{languageJson[language].FormKYC.Step4.noteLabel}</span>
                            {languageJson[language].FormKYC.Step4.noteMessage}
                        </span>
                    </div>
                </div>
            </div>
            <button className={boton + " boton-continuar"} onClick={() => boton === "boton-enabled" ? actualizarInformacionPersonal() : null}>{languageJson[language].FormKYC.Step4.botonContinuar}</button>
        </div>

    );
}

export default Step4;