import React, { useState, useEffect, useContext } from 'react';

//Imports librerias externas
import { Link } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//Imports componentes auxiliares
import LateralVector from '../../Utils/LateralVector/LateralVector';
import GeneralContext from '../../context/GeneralContext';
import { guardarSesion } from '../../Utils/Utils';
import EEUU from '../../Utils/Flags/EEUU';
import SPAIN from '../../Utils/Flags/SPAIN';
import { URL_API } from '../../Config/config';
import languageJson from '../../Utils/language.json';

//TODO organizar clases Flex-Box de forma genereal, optimizar clases reutilizadas
import './SignIn.scss';

const SignIn = () => {
  //Context
  const GC = useContext(GeneralContext);
  const { user, modificarUser, language, modificarLanguaje } = GC;

  const navigate = useNavigate();

  const [formulario, setFormulario] = useState({
    // State para informacion de usuario
    email: '',
    password: '',
  });

  const [boton, setBoton] = useState('boton-continuar-disabled'); // State para claseName de boton "Continuar"
  const [input, setInput] = useState('input'); // State para className de input

  const [ojo, setOjo] = useState('password');

  const cambiarPaso = async (e) => {
    e.preventDefault();
    if (comprobarFormulario()) {
      try {
        const response = await axios.post(URL_API + 'api/authentication/login', formulario);

        if (response.data.success) {
          let newUser = response.data.user;
          newUser.bearer = response.data.token;
          newUser.code = response.data.code;
          newUser.validate = false;

          modificarUser(newUser);
          guardarSesion(newUser);

          navigate('/Registro');
        } else {
          setInput('input-error');
        }
      } catch (error) {
        console.log(error);
        console.log(languageJson[language]);
        toast.error(languageJson[language].ToastMessage.error8);
        setInput('input-error');
      }
    }
  };

  const comprobarFormulario = React.useCallback(() => {
    return formulario.email !== '' && formulario.password !== '';
  }, [formulario]);

  // Cambio en tiempo real para className boton "Continuar" e input
  useEffect(() => {
    if (user !== null) navigate('/Registro');
    if (comprobarFormulario()) {
      setBoton('boton-continuar-enabled');
    } else {
      if (boton !== 'boton-continuar-disabled') setBoton('boton-continuar-disabled');
    }
  }, [boton, setBoton, formulario, comprobarFormulario, user, navigate]);

  return (
    <div className="general-body-sign-in direction-row justify-center align-center">
      <header>
        <div className="direction-row justify-between align-center" style={{ width: 120, margin: 20 }}>
          <div onClick={() => modificarLanguaje('ES')}>
            <SPAIN language={language} />
          </div>

          <div onClick={() => modificarLanguaje('EN')}>
            <EEUU language={language} />
          </div>
        </div>
      </header>
      <LateralVector lado="izq" mostrarInfo={true} results={false} />
      <div className="componente-mitad direction-column justify-center align-center">
        <div className="formulario-login">
          <div className="espacio-titulo direction-row justify-start align-start">
            <img src="/img/logo-cab-risk.png" className="logo-img" alt="logo"></img>
            <img src="/img/titulo-cab-risk.png" className="titulo-img" alt="titulo"></img>
          </div>
          <p className="titulo-1">{languageJson[language].SignIn.titulo1}</p>
          <p className="titulo-2">{languageJson[language].SignIn.titulo2}</p>
          <form onSubmit={cambiarPaso}>
            <div className="espacio-input direction-column justify-start align-start">
              <p className="titulo-2">{languageJson[language].SignIn.titulo3}</p>
              <input
                type="email"
                className="input"
                placeholder={languageJson[language].SignIn.placeholder1}
                onChange={(e) => setFormulario({ ...formulario, email: e.target.value })}></input>
            </div>
            <div className="espacio-input">
              <p className={input === 'input' ? 'titulo-2' : 'titulo-2-error'}>
                {languageJson[language].SignIn.titulo4}
              </p>
              <input
                type={ojo}
                className={input}
                placeholder={languageJson[language].SignIn.placeholder1}
                onChange={(e) => setFormulario({ ...formulario, password: e.target.value })}></input>
               {input === 'input-error' && <p className="texto-error " style={{marginTop:"0.8rem",fontSize:"18px"}}>{languageJson[language].SignIn.credencialesInvalidas}</p>}
           <img
                src="/img/ojo.png"
                className={input === 'input' ? 'ojo' : 'ojo-error'}
                alt="ojo"
                style={{marginBottom:"1rem",position:"absolute",top:"3rem"}}
                onClick={() => setOjo(ojo === 'password' ? 'text' : 'password')}></img>
            </div>
            <Link to="/Recuperar" className="olvidar-pass">
              <p>{languageJson[language].SignIn.texto1}</p>
            </Link>
            <Link to="/Registro" className="olvidar-pass">
              <p>{languageJson[language].SignIn.texto2}</p>
            </Link>
            <button className={boton} type="submit">
              {languageJson[language].SignIn.textoBoton}
            </button>
          </form>
        </div>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default SignIn;
