import React, {useState, useEffect, useContext} from 'react'

//Imports librerias externas
import { Link } from 'react-router-dom';
import { toast, Toaster } from 'react-hot-toast';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//Imports componentes auxiliares
import GeneralModal from '../../Utils/GeneralModal/GeneralModal';
import GeneralContext from '../../context/GeneralContext'
import LateralVector from '../../Utils/LateralVector/LateralVector';
import {validarContrasena} from '../../Utils/Utils'
import { URL_API } from '../../Config/config';
import EEUU from '../../Utils/Flags/EEUU';
import SPAIN from '../../Utils/Flags/SPAIN';
import languageJson from '../../Utils/language.json';

import "./PasswordRecovery.scss"

const PasswordRecovery = () => {

    //Context
    const GC = useContext(GeneralContext);
    const { language, modificarLanguaje } = GC;

    const navigator = useNavigate();

    const [formulario, setFormulario] = useState({ // State para informacion de usuario
        codigo: "",
        email: "",
        password: "",
        confirmPassword: "",
    })


    const [paso, setPaso] = useState(1) // State para asignacion de paso de registro

    const [ojo, setOjo] = useState("password");
    
    const [boton, setBoton] = useState("boton-disabled") // State para claseName de boton "Continuar"
    const [input, setInput] = useState("input") // State para className de input

    const [code, setCode] = useState("")
   
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const cambiarPaso = async () => {
        if(paso === 1 && comprobarFormulario1()){
            try{
                const response = await axios.post(URL_API + 'api/authentication/send-email-password-reset', {email: formulario.email})
                if(response.data.success){
                    setCode(response.data.code)
                    toast.success(languageJson[language].ToastMessage.success1)
                    setBoton("boton-disabled")
                    setInput("input")
                    setPaso(2)
                }else{
                    setInput("input-error")
                }
            }catch(error){
                console.log(error.response)
                if(error.response.status === 401)
                    toast.error(languageJson[language].ToastMessage.error7)
            }
        }else if(paso === 2 && comprobarFormulario2()){
            try{
                const response = await axios.post(URL_API + 'api/authentication/password-reset', {email: formulario.email, password: formulario.password })
                if(response.data.success){
                    setBoton("boton-disabled")
                    setInput("input")
                    setModalIsOpen(true);
                }else{
                    setInput("input-error")
                }
            }catch(error){
                console.log(error.response)
                if(error.response.status === 401)
                    toast.error(languageJson[language].ToastMessage.error7)
            }
        }else{
            toast.error(languageJson[language].ToastMessage.error10)
        }
    }

    const setPassword = (pass) =>{
        setFormulario({...formulario, password: pass})
        if(validarContrasena(pass)){
            if(input === "input-error") setInput("input")
        }else
            setInput("input-error")
    }


    //TODO Establecer comprobacion general
    const comprobarFormulario1 = React.useCallback(() => {
        return (formulario.email !== '')
    }, [formulario])

    //TODO Establecer comprobacion general
    const comprobarFormulario2 = React.useCallback(() => {
        return (formulario.password !== '' && formulario.confirmPassword !== '' && formulario.codigo === code && formulario.password === formulario.confirmPassword)
    }, [formulario, code])

    // Cambio en tiempo real para className boton "Continuar" e input
    useEffect(()=>{
        if(comprobarFormulario1() || comprobarFormulario2())
            setBoton("boton-enabled")
        else if(!comprobarFormulario1() && boton === "boton-enabled")
            setBoton("boton-disabled")
    }, [boton, setBoton, formulario, comprobarFormulario1, comprobarFormulario2])

    return ( 
        <div className="general-body direction-row justify-center align-center">
            <header>
                <div className="direction-row justify-between align-center" style={{ width: 180, margin: 20 }}>
                    <div className="direction-row justify-between align-center" style={{ width: 120, margin: 20 }}>
                        <div onClick={() => modificarLanguaje("ES")}><SPAIN language={language} /></div>
                        <div onClick={() => modificarLanguaje("EN")}><EEUU language={language} /></div>
                    </div>
                </div>
            </header>
            <div className="componente-mitad direction-column justify-center align-center">
                <div className="formulario-inicio direction-columna justify-start align-start">
                <div className="espacio-titulo direction-row justify-start align-start">
                    <img src="/img/logo-cab-risk.png" className="logo-img" alt="logo"></img>
                    <img src="/img/titulo-cab-risk.png" className="titulo-img" alt="titulo"></img>
                </div>
                {
                    (paso === 1) ?
                    <div>
                        <div className="espacio-subtitulo direction-row justify-start align-start">
                            <Link to="/"><img src="/img/flecha-atras.png" className="flecha-atras" alt='flecha-atras'></img></Link>
                            <p className="titulo-1">{languageJson[language].PasswordRecovery.titulo1}</p>
                        </div>
                        <p className="titulo-2">{languageJson[language].PasswordRecovery.titulo2}</p>
                        <div className="espacio-input">
                            <p className='titulo-2'>{languageJson[language].PasswordRecovery.titulo3}</p>
                            <input type="text" className="input" placeholder={languageJson[language].PasswordRecovery.placeholder1} onChange={(e) => setFormulario({ ...formulario, email: e.target.value })}></input>
                        </div>
                        <button className={"boton-continuar " + boton} onClick={() => cambiarPaso()}>{languageJson[language].PasswordRecovery.textoBoton}</button>
                    </div>
                    :
                    <div>
                        <div className="espacio-subtitulo">
                            <Link to="/"><img src="/img/flecha-atras.png" className="flecha-atras" alt='flecha-atras'></img></Link>
                            <p className="titulo-1">{languageJson[language].PasswordRecovery.titulo4}</p>
                        </div>
                        <div className="espacio-input">
                            <p className='titulo-2'>{languageJson[language].PasswordRecovery.titulo6}*</p>
                            <input type="text" className="input" placeholder={languageJson[language].PasswordRecovery.placeholder1} onChange={(e) => setFormulario({ ...formulario, codigo: e.target.value })}></input>
                        </div>
                        <div className="espacio-input">
                            <p className="titulo-2">{languageJson[language].PasswordRecovery.titulo4}*</p>
                            <input type={ojo} className={input} placeholder={languageJson[language].PasswordRecovery.placeholder1} value={formulario.password} onChange={(e) => setPassword(e.target.value)}></input>
                            { (input === "input-error") && <p className="texto-error">{languageJson[language].PasswordRecovery.textoError1}</p>}
                            <img src="/img/ojo.png" className={(input === "input-error") ? "ojo-error" : "ojo"} alt="ojo" onClick={() => setOjo(ojo === "password" ? "text" : "password")}></img>
                        </div>
                        <div className="espacio-input">
                            <p className="titulo-2">{languageJson[language].PasswordRecovery.titulo5}*</p>
                            <input type={ojo} className={formulario.password !== formulario.confirmPassword ? "input-error" : "input"} placeholder={languageJson[language].PasswordRecovery.placeholder1} onChange={(e) => setFormulario({ ...formulario, confirmPassword: e.target.value })}></input>
                            { (formulario.password !== formulario.confirmPassword) && <p className="texto-error">{languageJson[language].PasswordRecovery.textoError2}</p>}
                            <img src="/img/ojo.png" className={(formulario.password !== formulario.confirmPassword) ? "ojo-confirm-error" : "ojo"} alt="ojo" onClick={() => setOjo(ojo === "password" ? "text" : "password")}></img>
                        </div>
                        <button className={"boton-continuar " + boton} onClick={() => cambiarPaso()}>{languageJson[language].PasswordRecovery.textoBoton}</button>
                        <GeneralModal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen} contentLabel={"Cuenta creada"}>
                            <div className="modal-cuenta-creada">
                                <div className="fila-titulo">
                                    <img src="/img/check.png" className="check-img" alt="check"></img>
                                    <p className="titulo">{languageJson[language].PasswordRecovery.textoModalTitulo}</p>
                                </div>
                                <p className="texto">{languageJson[language].PasswordRecovery.textoModal}</p>
                                <button className = "boton boton-continuar boton-enabled" onClick={() => {
                                    setModalIsOpen(false)
                                    navigator("/")
                                }}>{languageJson[language].PasswordRecovery.textoModalBoton}</button>
                            </div>
                        </GeneralModal>
                    </div>
                }
                </div>
            </div>
            <LateralVector lado="der" mostrarInfo={true} results={false} />
            <Toaster position="top-right" reverseOrder={false} />
        </div>
     );
}
 
export default PasswordRecovery;