import React, { useState, useEffect } from 'react';

import languageJson from "../../Utils/language.json";

const Step2 = ({GC, seccion, setSeccion, toast}) => {

    const { user, language } = GC;
    const [boton, setBoton] = useState("boton-disabled") // State para claseName de boton "Continuar"
    
    useEffect(()=>{
        if(user?.persona_status === "Aprobado")
            setBoton("boton-enabled")
        else if(boton === "boton-enabled")
            setBoton("boton-disabled")
    }, [user, boton, setBoton])


    return ( 
        <div style={{color: "white", width: "82%", height: "650px", margin: 30}} className="direction-column justify-between align-start">
            <div className="direction-column justify-start align-start">
                <p className="titulo-1">{languageJson[language].FormKYC.Step3.titulo1}</p>
                {/* <p className="titulo-2">{languageJson[language].FormKYC.Step3.titulo2}</p> */}
                <div style={{width: "100%"}} className="direction-column justify-center align-center">
                    <img src="/img/personalogo.jpeg" style={{width: 100, objectFit: "contain", margin: 20, padding: 10, backgroundColor: "#fff", borderRadius: 8}} alt=""></img>
                    <img src="/img/biometria.svg" style={{width: 200, objectFit: "contain", margin: 20}} alt=""></img>
                </div>
                <div className="direction-row justify-center align-center">
                    <p className="titulo-1"><strong>{languageJson[language].FormKYC.Step3.estadoLabel}:</strong></p>
                    <p className="titulo-1" style={{color: (user?.persona_status === "Rechazado" ? "yellow" : (user?.persona_status === "Aprobado" ? "green" : "red")), marginLeft: 15}}>{user?.persona_status}</p>
                </div>
                <div className="direction-row justify-start align-center">
                <div className="direction-row justify-start align-center" style={{width: "500px"}}>
                    <p className="titulo-1"><strong>{languageJson[language].FormKYC.Step3.documentoFrontalLabel}:</strong></p>
                    <p className="titulo-1" style={{color: (user?.persona_status === "Aprobado" ? "green" : "red"), marginLeft: 15}}>{user?.persona_status}</p>
                </div>
                <div className="direction-row justify-center align-center">
                    {/* <p className="titulo-1" style={{marginLeft: 15}}><strong>{languageJson[language].FormKYC.Step3.mensajeLabel}:</strong></p>
                    <p className="titulo-1" style={{marginLeft: 15}}>{user?.document_front_status.message}</p> */}
                </div>
                </div>
                <div className="direction-row justify-start align-center">
                <div className="direction-row justify-start align-center" style={{width: "500px"}}>
                    <p className="titulo-1"><strong>{languageJson[language].FormKYC.Step3.documentoPosteriorLabel}:</strong></p>
                    <p className="titulo-1" style={{color: (user?.persona_status === "Aprobado" ? "green" : "red"), marginLeft: 15}}>{user?.persona_status}</p>
                </div>
                <div className="direction-row justify-center align-center">
                    {/* <p className="titulo-1" style={{marginLeft: 15}}><strong>{languageJson[language].FormKYC.Step3.mensajeLabel}:</strong></p>
                    <p className="titulo-1" style={{marginLeft: 15}}>{user?.document_back_status.message}</p> */}
                </div>
                </div>
                <div className="direction-row justify-start align-center">
                <div className="direction-row justify-start align-center" style={{width: "500px"}}>
                    <p className="titulo-1"><strong>{languageJson[language].FormKYC.Step3.fotoSelfieLabel}:</strong></p>
                    <p className="titulo-1" style={{color: (user?.persona_status === "Aprobado" ? "green" : "red"), marginLeft: 15}}>{user?.persona_status}</p>
                </div>
                <div className="direction-row justify-center align-center">
                    {/* <p className="titulo-1" style={{marginLeft: 15}}><strong>{languageJson[language].FormKYC.Step3.mensajeLabel}:</strong></p>
                    <p className="titulo-1" style={{marginLeft: 15}}>{user?.photo_status.message}</p> */}
                </div>
                </div>
            </div>
            {/* Test: QA */}
            {/* <button className={boton + " boton-continuar boton-metamap"} onClick={() => (boton === "boton-enabled" ? setSeccion({...seccion, 3: "Completado", 4: "Activo"}) : null)}>{languageJson[language].FormKYC.Step3.botonContinuar}</button> */}
            <button className={"boton-enabled boton-continuar boton-metamap"} onClick={() => (setSeccion({...seccion, 3: "Completado", 4: "Activo"}))}>{languageJson[language].FormKYC.Step3.botonContinuar}</button>
        </div>
     );
}
 
export default Step2;