//CLASES AUXILIARES (Funciones de uso general)

import { ENCRIPTION_KEY } from '../Config/config';

import CryptoJS from 'crypto-js';

export function isEqual(obj1, obj2) {
  // Comprueba si los objetos son del mismo tipo
  if (typeof obj1 !== typeof obj2) {
    return false;
  }

  // Comprueba si ambos objetos son nulos
  if (obj1 === null && obj2 === null) {
    return true;
  }

  // Comprueba si ambos objetos son objetos o arrays
  if (typeof obj1 === 'object' && obj1 !== null && typeof obj2 === 'object' && obj2 !== null) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Comprueba si los objetos tienen la misma cantidad de propiedades
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Compara cada propiedad de forma recursiva
    for (let key of keys1) {
      if (!isEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  // Compara los valores directamente
  return obj1 === obj2;
}

// Función para encriptar los datos de sesión
function encriptarDatos(datos) {
  const datosString = JSON.stringify(datos);
  const clave = ENCRIPTION_KEY;

  return CryptoJS.AES.encrypt(datosString, clave).toString();
}

// Función para descifrar los datos de sesión
export function descifrarDatos(datosEncriptados) {
  try {
    const clave = ENCRIPTION_KEY;
    const bytesDescifrados = CryptoJS.AES.decrypt(datosEncriptados, clave);
    const datosDescifradosString = bytesDescifrados.toString(CryptoJS.enc.Utf8);

    return JSON.parse(datosDescifradosString);
  } catch (error) {
    console.error(error);
    return null;
  }

}

// Función para guardar la información de sesión en el localStorage
export const guardarSesion = (usuario) => {
  const datosEncriptados = encriptarDatos(usuario);

  const fechaExpiracion = new Date();
  fechaExpiracion.setDate(fechaExpiracion.getDate() + 1);

  const sesion = {
    datos: datosEncriptados,
    expiracion: fechaExpiracion.getTime(),
  };

  localStorage.setItem('sesionUsuario', JSON.stringify(sesion));
};

// Función para obtener y validar la información de sesión del localStorage
export const obtenerSesion = () => {
  const sesionGuardada = localStorage.getItem('sesionUsuario');

  if (sesionGuardada) {
    const sesion = JSON.parse(sesionGuardada);

    if (sesion.expiracion < Date.now()) {
      eliminarSesion();
      return null;
    }

    const datosDescifrados = descifrarDatos(sesion.datos);

    return datosDescifrados;
  }

  return null;
};

// Función para eliminar la información de sesión del localStorage
export const eliminarSesion = () => {
  localStorage.removeItem('sesionUsuario');
};

export const validarContrasena = (contrasena) => {
  // Expresión regular para validar la contraseña
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\':"\\\\|,.<>\\/?]).{8,}$');

  // Comprueba si la contraseña cumple con la expresión regular
  return regex.test(contrasena);
};

export const validarCorreo = (correo) => {
  const patron = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
  return patron.test(correo);
};

export function isPermitAge(birthday) {

  var actualDate = new Date();

  // Calcular la edad restando el año de nacimiento al año actual
  var age = actualDate.getFullYear() - birthday.getFullYear();

  // Verificar si el día y el mes de nacimiento ya han pasado este año
  if (
    birthday.getMonth() > actualDate.getMonth() ||
    (birthday.getMonth() === actualDate.getMonth() &&
      birthday.getDate() > actualDate.getDate())
  ) {
    age--;
  }

  // Verificar si la persona es mayor de edad (18 años o más)
  return age >= 18;
}