import React, { useState } from 'react'

//Imports librerias externas
import axios from 'axios';

//Imports componentes auxiliares
import { URL_API } from '../../Config/config';
import { guardarSesion } from '../../Utils/Utils'
import languageJson from "../../Utils/language.json";

const Step6 = ({ GC, seccion, setSeccion, toast }) => {
    // Configurar encabezados personalizados para evitar problemas de CORS
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

    const { user, modificarUser, modificarLoading, language } = GC;

    const [validacionSeleccionada, setValidacionSeleccionada] = useState("Persona") //State para tipo de validacion seleccionada [Persona, Documento]

    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const enviarFormulario = async () => {
        modificarLoading(true);
        try {
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`
            const response = await axios.put(URL_API + 'api/authentication/update-verification-residence', { form_status: "Enviado", time_form: Date.now() })
            if (response.data.success) {
                let newUser = response.data.user
                newUser.bearer = user.bearer
                newUser.code = user.code
                newUser.validate = true

                modificarUser(newUser)
                guardarSesion(newUser)
            } else {
                toast.error(languageJson[language].ToastMessage.error1)
            }
        } catch (error) {
            console.log(error)
            toast.error(languageJson[language].ToastMessage.error1)
        }
        modificarLoading(false);
    }

    return (
        <div style={{ color: "white", height: "650px", margin: 30 }} className="direction-column justify-start align-start">
            <div className="direction-column justify-start align-start">
                <p className="titulo-1">{languageJson[language].FormKYC.Step6.titulo1}</p>
                <div className="direction-row justify-between align-start" style={{ width: "570px" }}>
                    <p style={{ cursor: "pointer" }} className={"titulo-2 " + (validacionSeleccionada === "Persona" ? "linea-seleccion" : "")} onClick={() => setValidacionSeleccionada("Persona")}>{languageJson[language].FormKYC.Step6.titulo2}</p>
                    <p style={{ cursor: "pointer" }} className={"titulo-2 " + (validacionSeleccionada === "Identidad" ? "linea-seleccion" : "")} onClick={() => setValidacionSeleccionada("Identidad")}>{languageJson[language].FormKYC.Step6.titulo3}</p>
                    <p style={{ cursor: "pointer" }} className={"titulo-2 " + (validacionSeleccionada === "Biometria" ? "linea-seleccion" : "")} onClick={() => setValidacionSeleccionada("Biometria")}>{languageJson[language].FormKYC.Step6.titulo4}</p>
                </div>
                {
                    (validacionSeleccionada === "Persona") ?
                        <div className="direction-column justify-start align-start scroll-custom" style={{ marginTop: "40px", height: 500, overflow: "auto", paddingRight: 10 }}>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo1}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.country}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo2}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.person_type}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo3}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.fullname}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo4}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.identification_type}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo5}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.identification_number}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo6}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.date_of_birth ? formatDate(new Date(user.date_of_birth)) : ''}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo7}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.nationality}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo8}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.phone_number}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo9}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.occupation}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo10}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.employer_name}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo11}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.reason_for_user_CAB}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo12}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.estimated_annual_gross_income}</p>
                            </div>
                            <div className="direction-row justify-start align-center" style={{ width: "570px", height: 30, borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo13}</p>
                                <p className="titulo-2" style={{ width: "50%" }}>{user?.source_of_funds_and_wealth}</p>
                            </div>
                        </div>
                        :
                        (validacionSeleccionada === "Identidad") ?
                            <div className="direction-column justify-start align-start scroll-custom" style={{ marginTop: "40px", height: 500, overflow: "auto", paddingRight: 10 }}>
                                <div className="direction-row justify-start align-center" style={{ width: "570px", padding: "10px 0px", borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                    <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo14}</p>
                                    <img src={user?.photo_identity} style={{ width: "250px", objectFit: "contain", borderRadius: "5%" }} alt=""></img>
                                    {/* <img src={`${user?.photo_identity ? user?.photo_identity : "/img/face-scan.png"}?${Date.now()}`} style={{width: "250px", objectFit: "contain", borderRadius: "5%"}} alt=""></img> */}

                                </div>
                                <div className="direction-row justify-start align-center" style={{ width: "570px", padding: "10px 0px", borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                    <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo15}</p>
                                    {/* <img src={`${user?.document_front ? user.document_front : "img/frontal.svg"}?${Date.now()}`} style={{width: "250px", objectFit: "contain", margin: "20px 25px 20px 0px", borderRadius: "5%"}} alt=""></img> */}
                                    <img src={user?.document_front} style={{ width: "250px", objectFit: "contain", margin: "20px 25px 20px 0px", borderRadius: "5%" }} alt=""></img>
                                </div>
                                <div className="direction-row justify-start align-center" style={{ width: "570px", padding: "10px 0px", borderBottom: "1px solid #FFF", margin: "10px 0" }}>
                                    <p className="titulo-2" style={{ width: "50%" }}>{languageJson[language].FormKYC.Step6.campo16}</p>
                                    {/* <img src={`${user?.document_back ? user.document_back : "img/frontal.svg"}?${Date.now()}`} style={{width: "250px", objectFit: "contain", margin: "20px 25px 20px 0px", borderRadius: "5%"}} alt=""></img> */}
                                    <img src={user?.document_back} style={{ width: "250px", objectFit: "contain", margin: "20px 25px 20px 0px", borderRadius: "5%" }} alt=""></img>
                                </div>
                            </div>
                            :
                            <div className="direction-row justify-center align-center" style={{ width: "400px", marginTop: "10px", flexDirection: "column", backgroundColor: "#fff", borderRadius: 8, alignSelf: "center" }}>
                                <div style={{ display: "flex", marginLeft: "10px" }}>
                                    <img src="/img/completado.png" style={{ objectFit: "contain" }} alt=""></img>
                                    <p style={{ color: "black", fontWeight: "bold", padding: "20px" }}>
                                        {languageJson[language].FormKYC.Step6.messageFinalVerification}</p>
                                </div>
                                <img src="/img/personalogo.jpeg" style={{ width: "200px", objectFit: "contain", margin: "20px", padding: 10 }} alt=""></img>
                            </div>
                }
            </div>
            <button className="boton-enabled boton-continuar boton-resultados" onClick={() => enviarFormulario()}>{languageJson[language].FormKYC.Step6.botonContinuar}</button>
        </div>


    );
}

export default Step6;