import React, { useState, useEffect } from 'react';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
//Imports librerias externas
import axios from 'axios';

//Imports componentes auxiliares
import CABCalendar from '../CABCalendar';
import { URL_API } from '../../Config/config';
import { guardarSesion, isPermitAge } from '../../Utils/Utils';
import languageJson from '../../Utils/language.json';
import { NumericFormat } from "react-number-format";

const Step1 = ({ GC, seccion, setSeccion, toast }) => {
  const { user, modificarUser, modificarLoading, language } = GC;

  const [errorBirthday, setErrorBirthday] = useState(false);
  const [errorMsgBirthday, setErrorMsgBirthday] = useState(null);
  const [formulario, setFormulario] = useState({
    country: '',
    person_type: '',
    first_name: '',
    last_name: '',
    identification_type: '',
    identification_number: '',
    date_of_birth: '',
    nationality: '',
    phone_number: '',
    occupation: '',
    employer_name: '',
    reason_for_user_CAB: '',
    estimated_annual_gross_income: '',
    source_of_funds_and_wealth: '',
    razonsocial: '',
    legal_representative_name: '',
    company_name: '',
  });

  const [paises, setPaises] = useState([]);
  const [boton, setBoton] = useState('boton-disabled');
  const [comprobar, setComprobar] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false)
  const currentDate = new Date();
  const formattedCurrentDate = currentDate.toISOString().split('T')[0];

  //Funcion auxiliar para obtener los paises.
  const obtenerPaises = React.useCallback(async () => {
    const res = await axios.get('https://restcountries.com/v3.1/all');
    const paisesOrdenados = res.data.sort((a, b) => {
      const nombreA = a.name.common.toLowerCase();
      const nombreB = b.name.common.toLowerCase();
      if (nombreA < nombreB) return -1;
      if (nombreA > nombreB) return 1;
      return 0;
    });

    setPaises(paisesOrdenados);
  }, [setPaises]);

  //funcion auxiliar para validar formulario
  function validarAtributos(json) {
    for (let key in json) {
      if (json.hasOwnProperty(key) && json[key] === ''
        && key !== "razonsocial"
        && key !== "legal_representative_name"
        && key !== "company_name")
      {
        return false;
      }
    }
    return true;
  }

  const comprobarDiligenciamientoFormulario = React.useCallback(() => {
    let bandera = true;
    const dateOfBirthValid = (() => {
      if (formulario.date_of_birth) {
        const dateOfBirth = new Date(formulario.date_of_birth);
        const eighteenYearsAgo = new Date();
        eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);
        setErrorMessage(dateOfBirth < eighteenYearsAgo)
        return dateOfBirth < eighteenYearsAgo;
      }
      return false;
    })();

    const excludedFields = ['razonsocial', 'legal_representative_name', 'company_name'];
  
    for (let atributo in formulario) {
      if (
        (!formulario[atributo] || (atributo === 'date_of_birth' && !dateOfBirthValid)) &&
        !(
          formulario['person_type'] === 'natural' &&
          excludedFields.includes(atributo)
        )
      ) {
        bandera = false;
        break;
      }
    }

    return bandera;
  }, [formulario]);

  const initData = React.useCallback(() => {
    let formattedDate = user?.date_of_birth;
    // if (user?.date_of_birth) {
    //   // Obtener la parte de la cadena antes de "T"
    //   console.log('Entrad date ', user?.date_of_birth);
    //   const dateString = user?.date_of_birth.split('T')[0];

    //   // Crear un objeto Date a partir de la cadena
    //   const dateObj = new Date(dateString);

    //   // Obtener los componentes de la fecha
    //   const year = dateObj.getUTCFullYear();
    //   const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    //   const day = String(dateObj.getUTCDate()).padStart(2, '0');

    //   // Crear la fecha en el formato deseado
    //   formattedDate = `${year}-${month}-${day}`;
    //   console.log('Formated ', formattedDate);
    // }
    setFormulario({
      country: (user?.country !== 'N/A' ? user?.country : '') || '',
      person_type: (user?.person_type !== 'N/A' ? user?.person_type : '') || '',
      razonsocial: (user?.razonsocial !== 'N/A' ? user?.razonsocial : '' ) || '',
      legal_representative_name: (user?.legal_representative_name !== 'N/A' ? user?.legal_representative_name : '' ) || '',
      company_name: (user?.company_name !== 'N/A' ? user.company_name : '') || '',
      first_name: (user?.fullname !== 'N/A' ? (user?.fullname ? (user?.fullname).split(' ')[0] : '') : '') || '',
      last_name: (user?.fullname !== 'N/A' ? (user?.fullname ? (user?.fullname).split(' ')[1] : '') : '') || '',
      identification_type: (user?.identification_type !== 'N/A' ? user?.identification_type : '') || '',
      identification_number: (user?.identification_number != 0 ? user?.identification_number : '') || '',
      date_of_birth: formattedDate || '',
      nationality: (user?.nationality !== 'N/A' ? user?.nationality : '') || '',
      phone_number: (user?.phone_number != 0 ? user?.phone_number : '') || '',
      occupation: (user?.occupation !== 'N/A' ? user?.occupation : '') || '',
      employer_name: (user?.employer_name !== 'N/A' ? user?.employer_name : '') || '',
      reason_for_user_CAB: (user?.reason_for_user_CAB !== 'N/A' ? user?.reason_for_user_CAB : '') || '',
      estimated_annual_gross_income:
        (user?.estimated_annual_gross_income !== 'N/A' ? user?.estimated_annual_gross_income : '') || '',
      source_of_funds_and_wealth:
        (user?.source_of_funds_and_wealth !== 'N/A' ? user?.source_of_funds_and_wealth : '') || '',
    });
    setComprobar(true);
  }, [user]);

  const actualizarInformacionPersonal = async () => {
    if (validarAtributos(formulario)) {
      if (boton !== 'boton-disabled') {
        modificarLoading(true);
        try {
          const { first_name, last_name, ...rest } = formulario;
          const modifiedUser = { ...rest, fullname: `${first_name} ${last_name}`, email: user.email };
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`;
          const response = await axios.put(URL_API + 'api/authentication/update-personal-information', modifiedUser);
          console.log(response);
          if (response.data.success) {
            let newUser = response.data.user;
            newUser.bearer = user.bearer;
            newUser.code = user.code;
            newUser.validate = true;

            modificarUser(newUser);
            guardarSesion(newUser);

            setSeccion({ ...seccion, 1: 'Completado', 2: 'Activo' });
          } else {
            toast.error(languageJson[language].ToastMessage.error1);
          }
        } catch (error) {
          console.log(error);
          toast.error(languageJson[language].ToastMessage.error1);
        }
        modificarLoading(false);
      } else {
        toast.error(languageJson[language].ToastMessage.error2);
      }
    }
  };

  const setDateComponent = (date) => {
    console.log('date', date);
    const day = date?.getDate()
    const month = date?.getMonth()+1
    const year = date?.getFullYear()

    const dateString = `${year}-${month}-${day}`;
    const newDate = new Date(dateString);
    setFormulario({ ...formulario, date_of_birth: newDate })
  }

  useEffect(() => {
    if (paises.length === 0) obtenerPaises();
    if (!comprobar && user) {
      initData();
    }
    if (comprobarDiligenciamientoFormulario()) setBoton('boton-enabled');
    else if (boton === 'boton-enabled') setBoton('boton-disabled');
  }, [paises, obtenerPaises, comprobarDiligenciamientoFormulario, setBoton, comprobar, user, initData, boton]);

  return (
    <div
      style={{ color: 'white', width: '82%', height: '650px', margin: 30 }}
      className="direction-column justify-start align-start">
      <p className="titulo-1">{languageJson[language].FormKYC.Step1.titulo1}</p>
      <div
        className="direction-column justify-start align-start scroll-custom"
        style={{
          width: '570px',
          height: '530px',
          overflowY: 'auto',
          overflowX: 'none',
          padding: '10px 10px 10px 0px',
        }}>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label1}</p>
            <select
              className="input-select"
              value={formulario.country}
              onChange={(e) => setFormulario({ ...formulario, country: e.target.value })}>
              <option value="">{languageJson[language].FormKYC.Step1.placeholder1}</option>
              {paises.map((pais, index) => {
                return (
                  <option key={index} value={pais.name.common}>
                    {pais.name.common}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label2}</p>
            <select
              className="input-select"
              value={formulario.person_type}
              onChange={(e) => setFormulario({ ...formulario, person_type: e.target.value })}>
              <option value="">{languageJson[language].FormKYC.Step1.placeholder2}</option>
              <option value="natural">{languageJson[language].FormKYC.Step1.option1}</option>
              <option value="juridica">{languageJson[language].FormKYC.Step1.option2}</option>
            </select>
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label3}</p>
            <input
              type="text"
              className="input"
              value={formulario.first_name}
              placeholder={languageJson[language].FormKYC.Step1.placeholder3}
              onChange={(e) => setFormulario({ ...formulario, first_name: e.target.value })}></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label4}</p>
            <input
              type="text"
              className="input"
              value={formulario.last_name}
              placeholder={languageJson[language].FormKYC.Step1.placeholder4}
              onChange={(e) => setFormulario({ ...formulario, last_name: e.target.value })}></input>
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label5}</p>
            <select
              className="input-select"
              value={formulario.identification_type}
              onChange={(e) => setFormulario({ ...formulario, identification_type: e.target.value })}>
              <option value="">{languageJson[language].FormKYC.Step1.placeholder5}</option>
              <option value="cedula">{languageJson[language].FormKYC.Step1.option3}</option>
              <option value="ruc">{languageJson[language].FormKYC.Step1.option4}</option>
              <option value="pasaporte">{languageJson[language].FormKYC.Step1.option5}</option>
              <option value="otro">{languageJson[language].FormKYC.Step1.option6}</option>
            </select>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label6}</p>
            {/* <NumericFormat
              placeholder={languageJson[language].FormKYC.Step1.placeholder6}
              className="input"
              name="identificacion"
              value={formulario.identification_number}
              thousandSeparator=","
              autoComplete="off"
              autoCorrect="off"
              onChange={(e) => setFormulario({ ...formulario, identification_number: e.target.value })}
            /> */}
             <input
              type="text"
              className="input"
              value={formulario.identification_number}
              name="identificacion"
              placeholder={languageJson[language].FormKYC.Step1.placeholder6}
              autoComplete="off"
              autoCorrect="off"
              onChange={(e) => setFormulario({ ...formulario, identification_number: e.target.value })}></input> 
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label7}</p>
              <CABCalendar
                placeholder="DD/MM/AAAA"
                value={formulario.date_of_birth}
                onChangeValue={(value) => {
                  const permitAge = isPermitAge(new Date(value));
                  setErrorBirthday(permitAge);
                  setErrorMsgBirthday(null);
                  if (!permitAge) {
                    setErrorMsgBirthday(languageJson[language].FormKYC.Step1.messageDateOfBirth);
                  }
                  setFormulario({ ...formulario, date_of_birth: value });
                }}
              />
              {!errorMessage && (
                <span className='error-date'>{languageJson[language].FormKYC.Step1.messageDateOfBirth}</span>
              )}
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label8}</p>
            <input
              type="text"
              className="input"
              value={formulario.nationality}
              placeholder={languageJson[language].FormKYC.Step1.placeholder8}
              onChange={(e) => setFormulario({ ...formulario, nationality: e.target.value })}></input>
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label9}</p>
            <input
              type="number"
              className="input"
              value={formulario.phone_number}
              placeholder="+57 000 000 0000"
              onChange={(e) => setFormulario({ ...formulario, phone_number: e.target.value })}></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label10}</p>
            <input
              type="text"
              className="input"
              value={formulario.occupation}
              placeholder={languageJson[language].FormKYC.Step1.placeholder10}
              onChange={(e) => setFormulario({ ...formulario, occupation: e.target.value })}></input>
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2 margin-top-custom">{languageJson[language].FormKYC.Step1.label11}</p>
            <input
              type="text"
              className="input"
              value={formulario.employer_name}
              placeholder={languageJson[language].FormKYC.Step1.placeholder11}
              onChange={(e) => setFormulario({ ...formulario, employer_name: e.target.value })}></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">
              {languageJson[language].FormKYC.Step1.label12}
            </p>
            <input
              type="text"
              className="input"
              value={formulario.reason_for_user_CAB}
              placeholder={languageJson[language].FormKYC.Step1.placeholder12}
              onChange={(e) => setFormulario({ ...formulario, reason_for_user_CAB: e.target.value })}></input>
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step1.label13}</p>
            <NumericFormat
              thousandSeparator=","
              className="input"
              value={formulario.estimated_annual_gross_income}
              placeholder={languageJson[language].FormKYC.Step1.placeholder13}
              onChange={(e) => setFormulario({ ...formulario, estimated_annual_gross_income: e.target.value })}
            />
            {/* <input
              type="text"
              ></input> */}
          </div>
          <div className="espacio-input">
            <p className="titulo-2 margin-top-custom">{languageJson[language].FormKYC.Step1.label14}</p>
            <input
              type="text"
              className="input"
              value={formulario.source_of_funds_and_wealth}
              placeholder={languageJson[language].FormKYC.Step1.placeholder14}
              onChange={(e) => setFormulario({ ...formulario, source_of_funds_and_wealth: e.target.value })}></input>
          </div>
        </div>
        {formulario.person_type === 'juridica' &&
        <>
          <div className="direction-row justify-center align-center">
            <div class="espacio-input">
              <p className='titulo-2'>{languageJson[language].FormKYC.Step1.razonsocial}</p>
              <input
                type='text'
                className='input'
                value={formulario.razonsocial}
                placeholder={languageJson[language].FormKYC.Step1.placeholder10}
                onChange={(e) => setFormulario({ ...formulario, razonsocial: e.target.value })}
              ></input>
            </div>
            <div class="espacio-input">
              <p className='titulo-2'>{languageJson[language].FormKYC.Step1.legalRepresentativeName}</p>
              <input
                type='text'
                className='input'
                value={formulario.legal_representative_name}
                placeholder={languageJson[language].FormKYC.Step1.placeholder10}
                onChange={(e) => setFormulario({ ...formulario, legal_representative_name: e.target.value })}
              ></input>
            </div>
          </div>
          <div className="direction-row justify-center align-center">
            <div class="espacio-input">
              <p className='titulo-2'>{languageJson[language].FormKYC.Step1.companyName}</p>
              <input
                type='text'
                className='input'
                value={formulario.company_name}
                placeholder={languageJson[language].FormKYC.Step1.placeholder10}
                onChange={(e) => setFormulario({ ...formulario, company_name: e.target.value })}
              ></input>
            </div>
          </div>
        </>
        }
      </div>

      <button className={boton + ' boton-continuar'} onClick={() => actualizarInformacionPersonal()}>
        {languageJson[language].FormKYC.Step1.boton}
      </button>
    </div>
  );
};

export default Step1;
