import { CLIENT_ID_PAYPAL } from '../../Config/config'

import React from "react" ;

//Imports librerias externas
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

//Imports componentes auxiliares
import { guardarSesion } from '../Utils'
import { URL_API } from '../../Config/config';

const CheckoutPaypal = ({description, amount, GC}) => {

    const { user, modificarUser } = GC;

    const navigate = useNavigate();

    // creates a paypal order
    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    description: description,
                    amount: {
                        currency_code: "USD",
                        value: amount,
                    },
                },
            ],
        }).then((orderID) => {
            return orderID;
        });
    };

    const savePayment = async (details) =>{
        console.log("Entre")
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`
        try{
            const response = await axios.post(URL_API + 'api/payments', {
                "payment_method": "Paypal",
                "status_payment": "COMPLETED",
                "manual_approvement": true,
                "payment_response": details,
                "payment_type": (amount === 67 ? "Regular" : "Express"),
                "user": user._id,
                "id_payment": details.id,
                "amount": amount,
            })

            if(response.data.success){
                let newUser = user
                newUser.pago = response.data
                modificarUser(newUser)
                guardarSesion(newUser)

                navigate("/Formulario");
                return response.data.success
            }else{
                console.log("Error al guardar el pago")
            }
        }catch(error){
            console.error('Error:', error);
            return false;
        }
    }

    // check Approval
    const onApprove = (data, actions) => {
        return actions.order.capture().then(function (details) {
            savePayment(details)
        });
    };

    //capture likely error
    const onError = (data, actions) => {
        alert("An Error occured with your payment ");
    };


    return (
        <PayPalScriptProvider options = {{ "client-id": CLIENT_ID_PAYPAL }}>
            <PayPalButtons
                style = {{
                    layout: 'horizontal',
                    shape: 'pill',
                    color: 'black',
                }}
                createOrder = {createOrder}
                onApprove = {onApprove}
                onError = {onError}
            />
        </PayPalScriptProvider>
    );
}

export default CheckoutPaypal