import React, { useState, useEffect, useRef } from 'react';

//Imports librerias externas
import axios from 'axios';

//Imports componentes auxiliares
import { URL_API } from '../../Config/config';
import { guardarSesion } from '../../Utils/Utils';
import languageJson from '../../Utils/language.json';

import { NumericFormat } from 'react-number-format';

const Step5 = ({ GC, seccion, setSeccion, toast }) => {
  const { user, modificarUser, modificarLoading, language } = GC;

  const [formulario, setFormulario] = useState({
    bank_name: '',
    account_type: '',
    routing_number: '',
    account_number: '',
  });

  const [boton, setBoton] = useState('boton-disabled');
  const [comprobar, setComprobar] = useState(false);

  const fileInputDocumentoExtract = useRef(null);
  const fileInputDocumentoCertification = useRef(null);
  const fileInputDocumentoTax = useRef(null);

  const handleFileChangeDocument = (event, document) => {
    const file = event.target.files[0];
    if (file && file.type.includes("pdf")) handleUpload(file, document);
  };

  const handleUpload = React.useCallback(
    async (file, document) => {
      try {
        modificarLoading(true);
        const formData = new FormData();
        formData.append('userId', user._id);
        formData.append(document, file);
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`;
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const response = await axios.put(URL_API + 'api/authentication/update-bank', formData, config);
        if (response.data.success) {
          let newUser = response.data.user;
          newUser.bearer = user.bearer;
          newUser.code = user.code;
          newUser.validate = true;
          console.log('🚀 ~ file: Step5.js:49 ~ handleUpload ~ newUser.validate :', newUser.validate);
          modificarUser(newUser);
          guardarSesion(newUser);
        } else {
          toast.error(languageJson[language].ToastMessage.error6);
        }
        modificarLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(languageJson[language].ToastMessage.error3);
        modificarLoading(false);
      }
    },
    [user, modificarUser, toast, modificarLoading, language]
  );

  const initData = React.useCallback(() => {
    setFormulario({
      bank_name: user?.bank_name !== 'N/A' ? user?.bank_name : '',
      account_type: user?.account_type !== 'N/A' ? user?.account_type : '',
      routing_number: user?.routing_number !== 'N/A' ? user?.routing_number : '',
      account_number: user?.account_number !== 'N/A' ? user?.account_number : '',
    });
    setComprobar(true);
  }, [user]);

  const actualizarInformacionPersonal = async () => {
    if (validarAtributos(formulario)) {
      modificarLoading(true);
      try {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user?.bearer}`;
        const response = await axios.put(URL_API + 'api/authentication/update-verification-residence', formulario);
        if (response.data.success) {
          let newUser = response.data.user;
          newUser.bearer = user.bearer;
          newUser.code = user.code;
          newUser.validate = true;

          modificarUser(newUser);
          guardarSesion(newUser);

          setSeccion({ ...seccion, 5: 'Completado', 6: 'Activo' });
        } else {
          toast.error(languageJson[language].ToastMessage.error1);
        }
      } catch (error) {
        toast.error(languageJson[language].ToastMessage.error1);
        console.log(error);
      }
      modificarLoading(false);
    } else {
      toast.error(languageJson[language].ToastMessage.error2);
    }
  };

  //funcion auxiliar para validar formulario
  function validarAtributos(json) {
    for (let key in json) {
      if (key!="routing_number" && json.hasOwnProperty(key) && json[key] === '') {
        return false;
      }
    }
    return true;
  }

  //Funcion auxiliar: revisar si el usuario ya realizo el diligenciamiento del formulario del primer paso.
  const comprobarDiligenciamientoFormulario = React.useCallback(() => {
    let bandera = true;
    for (let atributo in formulario) {
      if (atributo != "routing_number" && !formulario[atributo]) {
        bandera = false;
        break;
      }
    }

    return bandera && user?.bank_statement && user?.bank_certificate && user?.tax_return ? true : false;
  }, [formulario, user]);

  useEffect(() => {
    if (!comprobar && user) initData();
    if (comprobarDiligenciamientoFormulario()) setBoton('boton-enabled');
    else if (boton === 'boton-enabled') setBoton('boton-disabled');
  }, [boton, comprobar, user, initData, comprobarDiligenciamientoFormulario]);
  
    const bankStatement = decodeURIComponent(
    user?.bank_statement?.split('/')[user?.bank_statement?.split('/').length - 1]??''
  );
    const bankCertificate = decodeURIComponent(
    user?.bank_certificate?.split('/')[user?.bank_certificate?.split('/').length - 1]??''
  );
    const taxReturn = decodeURIComponent(
    user?.tax_return?.split('/')[user?.tax_return?.split('/').length - 1]??''
  );

  return (
    <div
      style={{ color: 'white', width: '82%', height: '650px', margin: 50 }}
      className="direction-column justify-start align-start">
      <p className="titulo-1">{languageJson[language].FormKYC.Step5.titulo1}</p>
      <div
        className="direction-column justify-start align-start scroll-custom"
        style={{ width: '100%', height: '530px', overflowY: 'auto' }}>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step5.nombreBancoLabel}</p>
            <input
              type="text"
              className="input"
              value={formulario.bank_name}
              placeholder={languageJson[language].FormKYC.Step5.escribeAqui}
              onChange={(e) => setFormulario({ ...formulario, bank_name: e.target.value })}></input>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step5.tipoCuentaLabel}</p>
            <select
              className="input-select"
              value={formulario.account_type}
              onChange={(e) => setFormulario({ ...formulario, account_type: e.target.value })}>
              <option value="">{languageJson[language].FormKYC.Step5.selecciona}</option>
              <option value="Ahorros">{languageJson[language].FormKYC.Step5.ahorros}</option>
              <option value="Corriente">{languageJson[language].FormKYC.Step5.corriente}</option>
            </select>
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step5.numeroCuentaLabel}</p>
            <NumericFormat
              thousandSeparator=","
              className="input"
              value={formulario.account_number}
              placeholder={languageJson[language].FormKYC.Step5.escribeAqui}
              onChange={(e) => setFormulario({ ...formulario, account_number: e.target.value })}
            />
            {/* <input
              type="text"
              ></input> */}
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step5.routingNumber}</p>
            <NumericFormat
              thousandSeparator=","
              className="input"
              value={formulario.routing_number}
              placeholder={languageJson[language].FormKYC.Step5.routingNumberPlaceholder}
              onChange={(e) => setFormulario({ ...formulario, routing_number: e.target.value })}
            />
            {/* <input
              type="text"
              ></input> */}
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step5.extractoBancario}</p>
            <input
              accept="application/pdf"
              type="file"
              ref={fileInputDocumentoExtract}
              onChange={(e) => handleFileChangeDocument(e, 'extract')}
              style={{ display: 'none' }}
            />
            <label style={{ position: 'relative' }}>
              <button
                onClick={() => fileInputDocumentoExtract.current.click()}
                className="boton-documentos"
                style={{ marginTop: 0 }}>
                <p style={{marginLeft: "-45px"}}>{languageJson[language].FormKYC.Step5.adjuntarDocumento}</p>
              </button>
              <img src="/img/upload.svg" className="upload-img posterior" alt="" style={{ right: 45, top: 10 }}></img>
            </label>
            <span>{bankStatement}</span>
          </div>
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step5.certificacionBancaria}</p>
            <input
              accept="application/pdf"
              type="file"
              ref={fileInputDocumentoCertification}
              onChange={(e) => handleFileChangeDocument(e, 'certification')}
              style={{ display: 'none' }}
            />
            <label style={{ position: 'relative' }}>
              <button
                onClick={() => fileInputDocumentoCertification.current.click()}
                className="boton-documentos"
                style={{ marginTop: 0 }}>
                <p style={{marginLeft: "-45px"}}>{languageJson[language].FormKYC.Step5.adjuntarDocumento}</p>
              </button>
              <img src="/img/upload.svg" className="upload-img posterior" alt="" style={{ right: 45, top: 10 }}></img>
            </label>
            <span>{bankCertificate}</span>
          </div>
        </div>
        <div className="direction-row justify-center align-center">
          <div className="espacio-input">
            <p className="titulo-2">{languageJson[language].FormKYC.Step5.declaracionRenta}</p>
            <input
              accept="application/pdf"
              type="file"
              ref={fileInputDocumentoTax}
              onChange={(e) => handleFileChangeDocument(e, 'tax')}
              style={{ display: 'none' }}
            />
            <label style={{ position: 'relative' }}>
              <button
                onClick={() => fileInputDocumentoTax.current.click()}
                className="boton-documentos"
                style={{ marginTop: 0 }}>
                <p style={{marginLeft: "-45px"}}>{languageJson[language].FormKYC.Step5.adjuntarDocumento}</p>
              </button>
              <img src="/img/upload.svg" className="upload-img posterior" alt="" style={{ right: 45, top: 10 }}></img>
            </label>
                <span>{taxReturn}</span>
          </div>
        </div>
      </div>
      <button className={boton + ' boton-continuar'} onClick={() => boton === 'boton-enabled'? actualizarInformacionPersonal(): null}>
        {languageJson[language].FormKYC.Step5.botonContinuar}
      </button>
    </div>
  );
};

export default Step5;
