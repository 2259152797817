import React, { useState, useRef, useEffect, useCallback } from 'react';

//Imports librerias externas
import axios from 'axios';
import { Client } from 'persona'

//Imports componentes auxiliares
import { URL_API } from '../../Config/config';
import { guardarSesion } from '../../Utils/Utils';
import GeneralModal from '../../Utils/GeneralModal/GeneralModal';
import languageJson from '../../Utils/language.json';

const Step2 = ({ GC, seccion, setSeccion, toast }) => {

  const [showIframe, setShowIframe] = useState(false);

  const handleClick = () => {
    setShowIframe(!showIframe);
  };
  // Configurar encabezados personalizados para evitar problemas de CORS
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

  const { user, modificarUser, modificarLoading, language } = GC;

  const [modalIsOpen, setModalIsOpen] = useState(false); //State para manejo de modales
  const [validacionSeleccionada, setValidacionSeleccionada] = useState('Persona'); //State para tipo de validacion seleccionada [Persona, Documento]
  const [boton, setBoton] = useState('boton-disabled'); // State para claseName de boton "Continuar"


  const fileInputDocumentoFrontal = useRef(null);
  const fileInputDocumentoPosterior = useRef(null);

  const handleFileChangeDocument = (event, cara) => {
    const file = event.target.files[0];
    if (file) handleUpload(file, cara, file.name);
  };

  const handleUpload = React.useCallback(
    async (file, cara, nameDoc) => {
      try {
        modificarLoading(true);
        const formData = new FormData();
        formData.append('userId', user._id);
        formData.append('fileName', nameDoc);
        formData.append(cara, file);
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`;
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const response = await axios.put(URL_API + 'api/authentication/update-identity', formData, config);

        if (response.data.success) {
          let newUser = response?.data?.user;
          newUser.bearer = user?.bearer;
          newUser.code = user?.code;
          newUser.validate = true;
          modificarUser(newUser);
          guardarSesion(newUser);
        } else {
          toast.error(languageJson[language].ToastMessage.error1);
        }
        modificarLoading(false);
      } catch (error) {
        toast.error(languageJson[language].ToastMessage.error3);
        modificarLoading(false);
      }
    },
    [user, modificarUser, toast, modificarLoading, language]
  );

  const [withPersona, setWithPersona] = useState(null)

  const updatePersonaStatusValidation = async (status, inquiryId) => {
    try {
      modificarLoading(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`;
      const { data } = await axios.put(URL_API + 'api/authentication/validation-persona', {
        persona_status: status,
        inquiryId: inquiryId
      });

      if (data.success && status === "Aprobado") {
        let newUser = data.user;
        newUser.bearer = user.bearer;
        newUser.code = user.code;
        newUser.validate = true;
        modificarUser(newUser);
        guardarSesion(newUser);
        setSeccion({ ...seccion, 2: 'Completado', 3: 'Activo' });
      } else {
        toast.error(languageJson[language].ToastMessage.error1);
      }
      modificarLoading(false);
    } catch (error) {
      toast.error(languageJson[language].ToastMessage.error3);
      modificarLoading(false);
    }
  }

  const loadPersona = useCallback(
    () => {
      modificarLoading(true);
      console.log("load persona widget")
      const client = new Client({
        templateId: 'itmpl_wBJQaStoLAdExavmyAM45CVX',
        environmentId: 'env_3FfTmFK4XvL1g7mzStpbgR2W', //SANDBOX
        //environmentId: 'env_kK1dLJK45WfHcoDQKNkL5uen', //PRODUCTION
        onReady: () => { client.open() },
        onComplete: ({ inquiryId, status, fields }) => {
          if (status === "completed")
            updatePersonaStatusValidation("Aprobado", inquiryId)
          console.log(`Completed inquiry ${inquiryId} with status ${status}`);
          modificarLoading(false);
        },
        onCancel: ({ inquiryId, sessionToken }) => {
          console.log('onCancel')
          console.log(inquiryId);
          updatePersonaStatusValidation("Cancelado", inquiryId)
          modificarLoading(false);
        },
        onError: (error) => {
          console.log(error)
          updatePersonaStatusValidation("Error", 0)
          modificarLoading(false);
        },
      });
      setWithPersona(client);
    },
    [withPersona],
  )


  const pasoSeccion = async () => {
    try {
      modificarLoading(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`;
      // const response = await axios.put(URL_API + 'api/authentication/verification-metamap');
      /*
        TEST: salto del paso 2 Metamap
        para poder probar los demas flujos
        response.data.success
      */
      if (true) {
        // let newUser = response?.data?.user;
        // newUser.bearer = user?.bearer;
        // newUser.code = user?.code;
        // newUser.validate = true;
        // modificarUser(newUser);
        // guardarSesion(newUser);
        setSeccion({ ...seccion, 2: 'Completado', 3: 'Activo' });
      } else {
        toast.error(languageJson[language].ToastMessage.error1);
      }
      modificarLoading(false);
    } catch (error) {
      toast.error(languageJson[language].ToastMessage.error1);
      modificarLoading(false);
    }
  };

  useEffect(() => {
    if (user?.photo_identity && user?.document_front && user?.document_back) setBoton('boton-enabled');
    else if (boton === 'boton-enabled') setBoton('boton-disabled');
  }, [user, boton, setBoton]);
  const frontalIDNombreImagen = decodeURIComponent(
    user?.document_front?.split('/')[user?.photo_identity?.split('/').length - 1] ?? ''
  );
  const traseraIDNombreImagen = decodeURIComponent(
    user?.document_back?.split('/')[user?.document_back?.split('/').length - 1] ?? ''
  );

  return (
    <div
      style={{ color: 'white', width: '75%', margin: 30 }}
      className="direction-column justify-between align-start">
      <p className="titulo-1">{languageJson[language].FormKYC.Step2.titulo1}</p>
      <div style={{ width: "100%" }} className="direction-column justify-center align-center">
        <img src="/img/personalogo.jpeg" style={{ width: 100, objectFit: "contain", margin: 20, padding: 10 }} alt=""></img>
        <img src="/img/biometria.svg" style={{ width: 200, objectFit: "contain", margin: 20 }} alt=""></img>
      </div>
      <div className="direction-column justify-start align-start">
        <p className="titulo-1">{languageJson[language].FormKYC.Step2.titulo1}</p>
        <div className="direction-row justify-between align-start" style={{ width: '340px' }}>
          <p
            style={{ cursor: 'pointer' }}
            className={'titulo-2 ' + (validacionSeleccionada === 'Persona' ? 'linea-seleccion' : null)}
            onClick={() => setValidacionSeleccionada('Persona')}>
            {languageJson[language].FormKYC.Step2.titulo2}
          </p>
          <p
            style={{ cursor: 'pointer' }}
            className={'titulo-2 ' + (validacionSeleccionada === 'Documento' ? 'linea-seleccion' : null)}
            onClick={() => setValidacionSeleccionada('Documento')}>
            {languageJson[language].FormKYC.Step2.titulo3}
          </p>
        </div>
        {validacionSeleccionada === "Persona" ? (
          <div className="direction-row justify-between align-start" style={{ width: '650px', marginTop: '25px' }}>
            <div>
              <img
                // src={`${user?.photo_identity ? user?.photo_identity : '/img/face-scan.svg'}?${Date.now()}`}
                src={user?.photo_identity}
                style={{ width: '250px', objectFit: 'contain' }}
                alt=""></img>
              {/* <div style={{ position: 'relative' }}>
                <button onClick={() => setModalIsOpen(true)} className="boton-foto">
                  <p>{languageJson[language].FormKYC.Step2.boton4}</p>
                </button>
                {showIframe && (
                  <iframe
                    src={withPersona?.getIframeUrl()}
                    title='selfie'
                    width="600px"
                    height="400px"
                    frameBorder="0"
                  />
                )}
                <img src="/img/camera.svg" className="foto-img" alt=""></img>
              </div> */}
            </div>
            <div className="direction-column justify-start align-start">
              <p className="titulo-2">{languageJson[language].FormKYC.Step2.comoTomarFoto}</p>
              <div className="direction-row justify-start align-center">
                <div className="punto-step2"></div>
                <p className="titulo-2" style={{ width: 350 }}>
                  {languageJson[language].FormKYC.Step2.instruccion1}
                </p>
              </div>
              <div className="direction-row justify-start align-center">
                <div className="punto-step2"></div>
                <p className="titulo-2" style={{ width: 350 }}>
                  {languageJson[language].FormKYC.Step2.instruccion2}
                </p>
              </div>
              <div className="direction-row justify-start align-center" style={{ width: 350 }}>
                <div className="punto-step2"></div>
                <p className="titulo-2" style={{ width: 350 }}>
                  {languageJson[language].FormKYC.Step2.instruccion3}
                </p>
              </div>
              <div className="direction-row justify-start align-center" style={{ width: 350 }}>
                <div className="punto-step2"></div>
                <p className="titulo-2" style={{ width: 350 }}>
                  {languageJson[language].FormKYC.Step2.instruccion4}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="direction-column justify-start align-start" style={{ width: '620px'}}>
            {/* <p className="titulo-2">{languageJson[language].FormKYC.Step2.adjuntaDocumentos}</p> */}
            <div className="direction-row justify-center align-center">
              <div className="direction-column justify-start">
                <img
                  // src={`${user?.document_front ? user.document_front : 'img/frontal.svg'}?${Date.now()}`}
                  src={user?.document_front}
                  style={{ width: '250px', objectFit: 'contain', margin: '20px 25px 20px 0px' }}
                  alt=""></img>
                {/* <span style={{ fontSize: '0.8rem' }}>{frontalIDNombreImagen}</span> */}
              </div>
              <div className="direction-column justify-start">
                <img
                  // src={`${user?.document_back ? user.document_back : 'img/posterior.svg'}?${Date.now()}`}
                  src={user?.document_back}
                  style={{ width: '250px', objectFit: 'contain', margin: '20px 25px 20px 0px' }}
                  alt=""></img>
                {/* <span style={{ fontSize: '0.8rem' }}>{traseraIDNombreImagen}</span> */}
              </div>
            </div>
            <div className="direction-row justify-center align-center">
              <input
                type="file"
                ref={fileInputDocumentoFrontal}
                onChange={(e) => handleFileChangeDocument(e, 'frontal')}
                style={{ display: 'none' }}
              />
              <label style={{ position: 'relative' }}>
                <button  /*onClick={() => fileInputDocumentoFrontal.current.click()}*/ className="boton-documentos">
                  <p>{languageJson[language].FormKYC.Step2.boton1}</p>
                </button>
                {/* <img src="/img/upload.svg" className="upload-img frontal" alt=""></img> */}
              </label>
              <input
                type="file"
                ref={fileInputDocumentoPosterior}
                onChange={(e) => handleFileChangeDocument(e, 'posterior')}
                style={{ display: 'none' }}
              />
              <label style={{ position: 'relative' }}>
                <button /*onClick={() => fileInputDocumentoPosterior.current.click()}*/ className="boton-documentos">
                  <p>{languageJson[language].FormKYC.Step2.boton2}</p>
                </button>
                {/* <img src="/img/upload.svg" className="upload-img posterior" alt=""></img> */}
              </label>
            </div>
          </div>
        )}
      </div>
      <button className='boton-continuar boton-enabled' onClick={() => (boton === 'boton-enabled' ? setSeccion({ ...seccion, 2: 'Completado', 3: 'Activo' }) : loadPersona())}>
        {boton === 'boton-enabled' ? languageJson[language].FormKYC.Step2.boton3 : languageJson[language].FormKYC.Step2.initValidation}</button>
      {/* <button className={boton + ' boton-continuar'} onClick={() => (boton === 'boton-enabled' ? pasoSeccion() : null)}>
        {languageJson[language].FormKYC.Step2.boton3}
      </button> */}
      <GeneralModal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen} contentLabel={'Cuenta creada'}>
        <CameraModal GC={GC} setModalIsOpen={setModalIsOpen} toast={toast} />
      </GeneralModal>
    </div>
  );
};

export default Step2;


const CameraModal = ({ GC, setModalIsOpen, toast }) => {
  const { user, modificarUser, modificarLoading, language } = GC;
  const videoRef = useRef(null);
  const [mediaStream, setMediaStream] = useState(null);

  useEffect(() => {
    requestWebcamPermissions()
  }, []);


  const closeCamera = () => {
    if (mediaStream) {
      const tracks = mediaStream.getTracks();
      tracks.forEach((track) => track.stop());
      setMediaStream(null);
    }
  };

  // Solicita permisos para acceder a la webcam
  const requestWebcamPermissions = async () => {
    try {
      modificarLoading(true);
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      setMediaStream(stream); // Add a state to store the media stream

      videoRef.current.srcObject = stream;
      modificarLoading(false);
    } catch (error) {
      toast.error(languageJson[language].ToastMessage.error5);
      setModalIsOpen(false);
      modificarLoading(false);
    }
  };


  const handleCapture = React.useCallback(async () => {
    modificarLoading(true);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const photoURL = canvas.toDataURL('image/jpeg');

    if (photoURL) {
      // Crea un objeto Blob a partir de la imagen en base64
      const imageBuffer = base64ToBuffer(photoURL.split(',')[1]);
      const imageBlob = bufferToBlob(imageBuffer);
      // Crea un objeto File a partir del Blob
      const imageFile = new File([imageBlob], 'nombre_archivo.jpg', { type: 'image/jpeg' });

      try {
        const formData = new FormData();
        formData.append('userId', user._id);
        formData.append('photo', imageFile);
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`;
        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        const response = await axios.put(URL_API + 'api/authentication/update-photo', formData, config);
        if (response.data.success) {
          let newUser = response.data.user;
          newUser.bearer = user.bearer;
          newUser.code = user.code;
          newUser.validate = true;
          closeCamera();
          modificarUser(newUser);
          guardarSesion(newUser);
        } else {
          toast.error(languageJson[language].ToastMessage.error1);
        }
        setModalIsOpen(false);
        modificarLoading(false);
      } catch (error) {
        toast.error(languageJson[language].ToastMessage.error3);
        modificarLoading(false);
      }
    } else {
      toast.error(languageJson[language].ToastMessage.error4);
      modificarLoading(false);
    }
  }, [user, modificarUser, toast, modificarLoading, language]);

  // Convierte la base64 en un ArrayBuffer
  const base64ToBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const buffer = new ArrayBuffer(binaryString.length);
    const array = new Uint8Array(buffer);
    for (let i = 0; i < binaryString.length; i++) {
      array[i] = binaryString.charCodeAt(i);
    }
    return buffer;
  };

  // Convierte el ArrayBuffer en un objeto Blob
  const bufferToBlob = (buffer) => {
    return new Blob([buffer]);
  };


  return <div className="modal-foto">
    <video ref={videoRef} width="100%" height="90%" autoPlay></video>
    <button className="tomar-foto" onClick={() => handleCapture()}>
      {languageJson[language].FormKYC.Step2.boton4}
    </button>
  </div>

}
