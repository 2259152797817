import { 
    MODIFICAR_USER,
    LOADING,
    LANGUAGE
} from '../types'

const generalReducerApp = (state, action) => {
    switch(action.type) {
        case MODIFICAR_USER:
            return {
                ...state,
                user: action.payload
            }
        case LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case LANGUAGE:
            return {
                ...state,
                language: action.payload
            }
        default:  
            return state;
    }
}
export default generalReducerApp;