import React, {useContext} from 'react'

import './LateralVector.scss'
import languajeJson from '../language.json'
import GeneralContext from '../../context/GeneralContext'

const LateralVector = ({lado, mostrarInfo, results}) => {
    //Context
    const GC = useContext(GeneralContext);
    const { language } = GC;

    return ( 
        <div className="componente-vector direction-column justify-center align-center">
            <img src="/img/vector-fondo.png" className={"vector-fondo-" + lado} alt="vector"></img>
            {
                (mostrarInfo && !results) ?
                <div className={"espacio-info-" + lado}>
                    <p className="titulo-1">{languajeJson[language]["LateralVector"]["titulo1"]}</p>
                    <p className="titulo-2">{languajeJson[language]["LateralVector"]["titulo2"]}</p>
                </div>
                :
                <div className="espacio-info-results">
                    <p className="titulo-1">{languajeJson[language]["LateralVector"]["texto1"]}</p>
                </div>
            }
        </div>

     );
}
 
export default LateralVector;