import React, { useEffect, useContext, useState, useRef } from 'react';

//Imports librerias externas
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

//Imports componentes auxiliares
import LateralVector from '../../Utils/LateralVector/LateralVector';
import GeneralContext from '../../context/GeneralContext';
import { eliminarSesion, guardarSesion, obtenerSesion } from '../../Utils/Utils';
import { URL_API } from '../../Config/config';
import Loader from '../../Utils/loader/loader';
import EEUU from '../../Utils/Flags/EEUU';
import SPAIN from '../../Utils/Flags/SPAIN';
import languageJson from '../../Utils/language.json';

//TODO organizar clases Flex-Box de forma genereal, optimizar clases reutilizadas
import './Results.scss';

const SignIn = () => {
  //Context
  const GC = useContext(GeneralContext);
  const { user, modificarUser, isLoading, modificarLoading, language, modificarLanguaje } = GC;

  const navigate = useNavigate();

  const [documentos, setDocumentos] = useState(false);
  const [listaDocumentos, setListaDocumentos] = useState([]);
  const [document1Name, setdocument1Name] = useState("")
  const [document2Name, setdocument2Name] = useState("")
  const [document3Name, setdocument3Name] = useState("")
  const [document4Name, setdocument4Name] = useState("")

  useEffect(() => {
    setdocument1Name("");
    setdocument2Name("");
    setdocument3Name("");
    setdocument4Name("");
  }, []);

  useEffect(()=>{
    const newUser = obtenerSesion();
    if(!newUser)
        navigate("/");
}, [user])

  const logOut = async () => {
    modificarUser(null);
    eliminarSesion();
    navigate('/');
  };

  const fileInputDocumento1 = useRef(null);
  const fileInputDocumento2 = useRef(null);
  const fileInputDocumento3 = useRef(null);
  const fileInputDocumento4 = useRef(null);

  const handleFileChangeDocument = (event) => {
    const file = event.target.files[0];
    let newListaDocumentos = structuredClone(listaDocumentos);
    newListaDocumentos.push(file);
    setListaDocumentos(newListaDocumentos);
  };

  const handleUpload = React.useCallback(
    async (file) => {
      try {
        modificarLoading(true);
        for (let documento of listaDocumentos) {
          const formData = new FormData();
          formData.append('userId', user._id);
          formData.append('document', documento);
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`;
          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          const response = await axios.put(URL_API + 'api/authentication/additional-documents', formData, config);
          if (response.data.success) {
            let newUser = response.data.user;
            newUser.bearer = user.bearer;
            newUser.code = user.code;
            newUser.validate = true;
            modificarUser(newUser);
            guardarSesion(newUser);
            toast.success(languageJson[language].ToastMessage.success2);
            setDocumentos(false);
          } else {
            toast.error(languageJson[language].ToastMessage.error6);
          }
        }
        modificarLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(languageJson[language].ToastMessage.error3);
        modificarLoading(false);
      }
    },
    [user, modificarUser, modificarLoading, listaDocumentos, language]
  );

  // Cambio en tiempo real para className boton "Continuar" e input
  useEffect(() => {
    if (user?.form_status === 'No enviado') navigate('/Formulario');
  }, [user, navigate]);

  return (
    <div className="general-body-results direction-row justify-center align-center">
      <Toaster position="top-right" reverseOrder={false} />
      {isLoading && <Loader />}
      <LateralVector lado="izq" mostrarInfo={true} results={true} />
      <header>
        <img src="/img/Logo CAB-RISK-EMPRESA.svg" className="img-logo" alt="logo"></img>
        <div className="direction-row justify-between align-center" style={{ width: 180, margin: 20 }}>
          <div onClick={() => modificarLanguaje('ES')}>
            <SPAIN language={language} />
          </div>
          <div onClick={() => modificarLanguaje('EN')}>
            <EEUU language={language} />
          </div>
          <img src="/img/box-arrow-in-right.svg" className="img-arrow" alt="flecha" onClick={() => logOut()}></img>
        </div>
      </header>
      {!documentos ? (
        <div className="componente-mitad direction-column justify-center align-start">
          <p className="titulo-1">{languageJson[language].Results.titulo1}</p>
          <div
            className="direction-row justify-start align-center"
            style={{
              width: 570,
              marginTop: 60,
              position: 'relative',
              height: 86,
            }}>
            <img src="/img/enviado.svg" style={{ width: 60, objectFit: 'contain' }} alt=""></img>
            <div
              style={{
                height: 58,
                width: 3,
                backgroundColor: '#B7EB06',
                position: 'absolute',
                top: 72,
                left: 30,
              }}></div>
            <div className="direction-column justify-center align-start" style={{ marginLeft: 30 }}>
              <p className="subtitulo-1">{languageJson[language].Results.subtitulo1_1}</p>
              <p className="subtitulo-2">{languageJson[language].Results.subtitulo1_2}</p>
            </div>
          </div>
          <div
            className="direction-row justify-start align-center"
            style={{
              width: 570,
              marginTop: 30,
              position: 'relative',
              height: 86,
            }}>
            <img
              src={user?.form_status !== 'Enviado' ? '/img/revision-enabled.svg' : '/img/revision-disabled.svg'}
              style={{ width: 60, objectFit: 'contain' }}
              alt=""></img>
            <div
              style={{
                height: 58,
                width: 3,
                backgroundColor: user?.form_status !== 'Enviado' ? '#B7EB06' : '#B4BBC1',
                position: 'absolute',
                top: 72,
                left: 30,
              }}></div>
            {user?.form_status !== 'Enviado' && (
              <div className="direction-column justify-center align-start" style={{ marginLeft: 30 }}>
                <p className="subtitulo-1">{languageJson[language].Results.subtitulo2_1}</p>
                <p className="subtitulo-2">{languageJson[language].Results.subtitulo2_2}</p>
              </div>
            )}
          </div>
          <div
            className="direction-row justify-start align-center"
            style={{
              width: 570,
              marginTop: 30,
              position: 'relative',
              height: 86,
            }}>
            <img
              src={
                user?.form_status !== 'Enviado' && user?.form_status !== 'En revisión'
                  ? '/img/documentos-enabled.svg'
                  : '/img/documentos-disabled.svg'
              }
              style={{ width: 60, objectFit: 'contain' }}
              alt=""></img>
            <div
              style={{
                height: 58,
                width: 3,
                backgroundColor:
                  user?.form_status !== 'Enviado' && user?.form_status !== 'En revisión' ? '#B7EB06' : '#B4BBC1',
                position: 'absolute',
                top: 72,
                left: 30,
              }}></div>
            <div
              className="direction-column justify-center align-start"
              style={{
                width: 470,
                marginTop: 30,
                position: 'relative',
                height: 86,
              }}>
              {user?.form_status !== 'Enviado' && user?.form_status !== 'En revisión' && (
                <div className="direction-column justify-center align-start" style={{ marginLeft: 30 }}>
                  <p className="subtitulo-1">{languageJson[language].Results.subtitulo3_1}</p>
                  <p className="subtitulo-2">{languageJson[language].Results.subtitulo3_2}</p>
                </div>
              )}
              {user?.form_status === 'Solicitud de documentación' && (
                <div
                  className="direction-row justify-start align-center"
                  style={{ marginLeft: 10, cursor: 'pointer' }}
                  onClick={() => setDocumentos(true)}>
                  <img
                    src="/img/boton-mas.svg"
                    style={{
                      width: 20,
                      objectFit: 'contain',
                      marginLeft: 20,
                      marginRight: 10,
                    }}
                    alt=""></img>
                  <p className="subtitulo-2">{languageJson[language].Results.subtitulo3_3}</p>
                </div>
              )}
            </div>
          </div>
          <div
            className="direction-row justify-start align-center"
            style={{
              width: 570,
              marginTop: 30,
              position: 'relative',
              height: 86,
            }}>
            <img
              src={
                user?.form_status !== 'Enviado' &&
                user?.form_status !== 'En revisión' &&
                user?.form_status !== 'Solicitud de documentación'
                  ? '/img/resultados-enabled.svg'
                  : '/img/resultados-disabled.svg'
              }
              style={{ width: 60, objectFit: 'contain' }}
              alt=""></img>
            {user?.form_status !== 'Enviado' &&
              user?.form_status !== 'En revisión' &&
              user?.form_status !== 'Solicitud de documentación' && (
                <div className="direction-column justify-center align-start" style={{ marginLeft: 30 }}>
                  <p className="subtitulo-1">{languageJson[language].Results.subtitulo4_1}</p>
                  <p className="subtitulo-2">
                    {user?.form_status === 'KYC Aprobado'
                      ? languageJson[language].Results.subtitulo4_2_1
                      : languageJson[language].Results.subtitulo4_2_2}
                  </p>
                </div>
              )}
          </div>
        </div>
      ) : (
        <div className="componente-mitad direction-column justify-start align-start" style={{ height: 600 }}>
          <div className="espacio-titulo direction-row justify-center align-start" style={{ height: 30 }}>
            <img
              src="/img/flecha-atras.png"
              alt="logo"
              onClick={() => setDocumentos(false)}
              style={{ cursor: 'pointer' }}></img>
            <p className="titulo-1" style={{ marginTop: 0, marginLeft: 20 }}>
              {languageJson[language].Results.titulo2}
            </p>
          </div>
          <div className="direction-row justify-center align-center">
            <div className="espacio-input">
              <p className="titulo-2">{languageJson[language].Results.documento1}</p>
              <input
                type="file"
                ref={fileInputDocumento1}
                onChange={(e) => {                
                  setdocument1Name(e.target.files[0].name);
                  handleFileChangeDocument(e)}}
                style={{ display: 'none' }}
              />
              <label style={{ position: 'relative' }}>
                <button
                  onClick={() => fileInputDocumento1.current.click()}
                  className="boton-documentos"
                  style={{ marginTop: 0 }}>
                  <p>{languageJson[language].Results.adjuntarDocumento}</p>
                </button>
                <img src="/img/upload.svg" className="upload-img posterior" alt="" style={{ right: 45, top: 10 }}></img>
              </label>
              <span style={{color:"white"}}>{document1Name}</span>
            </div>
            <div className="espacio-input">
              <p className="titulo-2">{languageJson[language].Results.documento2}</p>
              <input
                type="file"
                ref={fileInputDocumento2}
                onChange={(e) => {
                    setdocument2Name(e.target.files[0].name);
                  handleFileChangeDocument(e)}}
                style={{ display: 'none' }}
              />
              <label style={{ position: 'relative' }}>
                <button
                  onClick={() => fileInputDocumento2.current.click()}
                  className="boton-documentos"
                  style={{ marginTop: 0 }}>
                  <p>{languageJson[language].Results.adjuntarDocumento}</p>
                </button>
                <img src="/img/upload.svg" className="upload-img posterior" alt="" style={{ right: 45, top: 10 }}></img>
              </label>
              <span style={{color:"white"}}>{document2Name}</span>
            </div>
          </div>
          <div className="direction-row justify-center align-center">
            <div className="espacio-input">
              <p className="titulo-2">{languageJson[language].Results.documento3}</p>
              <input
                type="file"
                ref={fileInputDocumento3}
                onChange={(e) => {
                  setdocument3Name(e.target.files[0].name);
                  handleFileChangeDocument(e)}}
                style={{ display: 'none' }}
              />
              <label style={{ position: 'relative' }}>
                <button
                  onClick={() => fileInputDocumento3.current.click()}
                  className="boton-documentos"
                  style={{ marginTop: 0 }}>
                  <p>{languageJson[language].Results.adjuntarDocumento}</p>
                </button>
                <img src="/img/upload.svg" className="upload-img posterior" alt="" style={{ right: 45, top: 10 }}></img>
              </label>
               <span style={{color:"white"}}>{document3Name}</span>
            </div>
            <div className="espacio-input">
              <p className="titulo-2">{languageJson[language].Results.documento4}</p>
              <input
                type="file"
                ref={fileInputDocumento4}
                onChange={(e) => {
                  setdocument4Name(e.target.files[0].name);
                  handleFileChangeDocument(e)}}
                style={{ display: 'none' }}
              />
              <label style={{ position: 'relative' }}>
                <button
                  onClick={() => fileInputDocumento4.current.click()}
                  className="boton-documentos"
                  style={{ marginTop: 0 }}>
                  <p>{languageJson[language].Results.adjuntarDocumento}</p>
                </button>
                <img src="/img/upload.svg" className="upload-img posterior" alt="" style={{ right: 45, top: 10 }}></img>
              </label>
              <span style={{color:"white"}}>{document4Name}</span>
            </div>
          </div>
          <button className="boton-enabled boton-continuar" onClick={() => handleUpload()}>
            {languageJson[language].Results.enviar}
          </button>
        </div>
      )}

      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default SignIn;
