import React, { useContext } from 'react'
import languageJson from '../../Utils/language.json';
import GeneralContext from '../../context/GeneralContext';

const Flow = ({seccion}) => {

    const {language} = useContext(GeneralContext);

    return ( 
        <div className = "pasos">
            <div style = {{position: "relative"}}>
                <div className = 'linea-vertical-izq-abajo linea-selected'></div>
                <div className = 'linea-horizontal-izq linea-selected'></div>
                <div className = "fila-info-selected">
                    {
                        (seccion[1] === "Completado") ? 
                            <img src = "/img/completado.png" className = "img-check" alt = "check"></img> 
                            : 
                            <p className = {(seccion[1] === "Activo") ? "numero-info-selected" : "numero-info-inselected"}>1</p>
                    }
                    <div className = "direction-column justify-start align-start">
                        <p className = "texto-info">{languageJson[language].Flow.step1}</p>
                        <p className = {"texto-info " + (seccion[1] === "Activo" || seccion[1] === "Completado" ? "verde" : null)}>{languageJson[language].Flow[seccion[1]]}</p>
                    </div>
                </div>
            </div>
            <div style = {{position: "relative"}}>
                <div className = 'linea-vertical-izq-arriba linea-selected'></div>
                <div className = 'linea-horizontal-izq linea-selected'></div>
                <div className = {(seccion[2] === "Activo" || seccion[2] === "Completado" ? "fila-info-selected" : "fila-info-inselected")}>
                    {
                        (seccion[2] === "Completado") ? 
                            <img src = "/img/completado.png" className = "img-check" alt = "check"></img> 
                            : 
                            <p className = {(seccion[2] === "Activo") ? "numero-info-selected" : "numero-info-inselected"}>2</p>
                    }
                    <div className = "direction-column justify-start align-start">
                        <p className = "texto-info">{languageJson[language].Flow.step2}</p>
                        <p className = {"texto-info " + (seccion[2] === "Activo" || seccion[2] === "Completado" ? "verde" : null)}>{languageJson[language].Flow[seccion[2]]}</p>
                    </div>
                    
                </div>
                <div className = {'linea-vertical-der-abajo ' + (seccion[2] === "Activo" || seccion[2] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {'linea-horizontal-der ' + (seccion[2] === "Activo" || seccion[2] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
            </div>
            <div style = {{position: "relative"}}>
                <div className = {'linea-vertical-der-arriba ' + (seccion[2] === "Activo" || seccion[2] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {'linea-horizontal-der ' + (seccion[2] === "Activo" || seccion[2] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {(seccion[3] === "Activo" || seccion[3] === "Completado" ? "fila-info-selected" : "fila-info-inselected")}>
                    {
                        (seccion[3] === "Completado") ? 
                            <img src = "/img/completado.png" className = "img-check" alt = "check"></img> 
                            : 
                            <p className = {(seccion[3] === "Activo") ? "numero-info-selected" : "numero-info-inselected"}>3</p>
                    }
                    <div className = "direction-column justify-start align-start">
                        <p className = "texto-info">{languageJson[language].Flow.step3}</p>
                        <p className = {"texto-info " + (seccion[3] === "Activo" || seccion[3] === "Completado" ? "verde" : null)}>{languageJson[language].Flow[seccion[3]]}</p>
                    </div>
                    
                </div>
                <div className = {'linea-vertical-izq-abajo ' + (seccion[3] === "Activo" || seccion[3] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {'linea-horizontal-izq ' + (seccion[3] === "Activo" || seccion[3] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
            </div>
            <div style = {{position: "relative"}}>
                <div className = {'linea-vertical-izq-arriba ' + (seccion[3] === "Activo" || seccion[3] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {'linea-horizontal-izq ' + (seccion[3] === "Activo" || seccion[3] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {(seccion[4] === "Activo" || seccion[4] === "Completado" ? "fila-info-selected" : "fila-info-inselected")}>
                    {
                        (seccion[4] === "Completado") ? 
                            <img src = "/img/completado.png" className = "img-check" alt = "check"></img> 
                            : 
                            <p className = {(seccion[4] === "Activo") ? "numero-info-selected" : "numero-info-inselected"}>4</p>
                    }
                    <div className = "direction-column justify-start align-start">
                        <p className = "texto-info">{languageJson[language].Flow.step4}</p>
                        <p className = {"texto-info " + (seccion[4] === "Activo" || seccion[4] === "Completado" ? "verde" : null)}>{languageJson[language].Flow[seccion[4]]}</p>
                    </div>
                    
                </div>
                <div className = {'linea-vertical-der-abajo ' + (seccion[4] === "Activo" || seccion[4] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {'linea-horizontal-der ' + (seccion[4] === "Activo" || seccion[4] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
            </div>
            <div style = {{position: "relative"}}>
                <div className = {'linea-vertical-der-arriba ' + (seccion[4] === "Activo" || seccion[4] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {'linea-horizontal-der ' + (seccion[4] === "Activo" || seccion[4] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {(seccion[5] === "Activo" || seccion[5] === "Completado" ? "fila-info-selected" : "fila-info-inselected")}>
                    {
                        (seccion[5] === "Completado") ? 
                            <img src = "/img/completado.png" className = "img-check" alt = "check"></img> 
                            : 
                            <p className = {(seccion[5] === "Activo") ? "numero-info-selected" : "numero-info-inselected"}>5</p>
                    }
                    <div className = "direction-column justify-start align-start">
                        <p className = "texto-info">{languageJson[language].Flow.step5}</p>
                        <p className = {"texto-info " + (seccion[5] === "Activo" || seccion[5] === "Completado" ? "verde" : null)}>{languageJson[language].Flow[seccion[5]]}</p>
                    </div>
                </div>
                <div className = {'linea-vertical-izq-abajo ' + (seccion[5] === "Activo" || seccion[5] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {'linea-horizontal-izq ' + (seccion[5] === "Activo" || seccion[5] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
            </div>
            <div style = {{position: "relative"}}>
                <div className = {'linea-vertical-izq-arriba ' + (seccion[5] === "Activo" || seccion[5] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {'linea-horizontal-izq ' + (seccion[5] === "Activo" || seccion[5] === "Completado" ? 'linea-selected' : 'linea-inselected')}></div>
                <div className = {(seccion[6] === "Activo" || seccion[6] === "Completado" ? "fila-info-selected" : "fila-info-inselected")}>
                    <p className = {(seccion[6] === "Activo") ? "numero-info-selected" : "numero-info-inselected"}>6</p>
                    <div className = "direction-column justify-start align-start">
                        <p className = "texto-info">{languageJson[language].Flow.step6}</p>
                    </div>
                </div>
            </div>
        </div>
        
     );
}
 
export default Flow;