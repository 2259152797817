//Librerias importadas
import { Routes, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";

import SignUp from './components/SignUp/SignUp';
import SignIn from './components/SignIn/SignIn'
import PasswordRecovery from './components/PasswordRecovery/PasswordRecovery'
import FormKYC from './components/FormKYC/FormKYC';
import Results from './components/Results/Results';

import GeneralState from './context/GeneralState'


//TODO revisar reutilizacion de componentes
function App() {
  return (<>
    <GeneralState>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/Registro" element={<SignUp />} />
        <Route path="/Recuperar" element={<PasswordRecovery />} />
        <Route path="/Formulario" element={<FormKYC />} />
        <Route path="/Resultados" element={<Results />} />
      </Routes>
    </GeneralState>
    <Helmet>
      <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=4706bc1f-8968-46fa-a576-81b0486b6f7b"> </script>
    </Helmet>
  </>
  );
}

export default App;
