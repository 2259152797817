import React, { useState, useEffect, useCallback } from 'react'

//Imports librerias externas
import { Link } from 'react-router-dom';
import axios from 'axios';

//Imports componentes auxiliares
import { validarContrasena, validarCorreo, guardarSesion } from '../../Utils/Utils'
import { URL_API } from '../../Config/config';
import languajeJson from '../../Utils/language.json';

const Step1 = ({ cabCapital, setPaso, GC, toast }) => {

    //-------------------------------------------------------------------------------------------------------

    //Context
    const { user, modificarUser, language } = GC;

    //-------------------------------------------------------------------------------------------------------

    const [ojo, setOjo] = useState("password"); //State para tipo de input de contraseña

    //-------------------------------------------------------------------------------------------------------

    const [formulario, setFormulario] = useState({ // State para informacion de usuario
        bussiness_name: "",
        email: "",
        password: "",
        confirmPassword: ""
    })

    //-------------------------------------------------------------------------------------------------------

    //Funcion auxiliar para validar formulario
    const validarFormulario = React.useCallback(() => {
        return (formulario.bussiness_name !== "" && validarCorreo(formulario.email) && validarContrasena(formulario.password) && formulario.confirmPassword === formulario.password)
    }, [formulario])

    //Funcion principal, revision de datos, creacion de usuario y cambio de paso en proceso de registro
    const cambiarPaso = React.useCallback(async () => {
        if (validarFormulario()) {
            sendRequest(formulario);
        }
    }, [formulario, setPaso, modificarUser, validarFormulario])

    const sendRequest = async (data) => {
        try {
            console.log(data)
            // Configurar encabezados personalizados para evitar problemas de CORS
            axios.defaults.headers.post['Content-Type'] = 'application/json';
            axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
            const response = await axios.post(URL_API + 'api/authentication/register', data)

            let newUser = response.data.user
            newUser.bearer = response.data.token
            newUser.code = response.data.code
            newUser.validate = false

            modificarUser(newUser)
            guardarSesion(newUser)

            if (response.data.success)
                setPaso(2);
            else
                alert("Error al crear usuario")
        } catch (error) {
            console.error('Error:', error);
            toast.error(error?.response?.data?.errors[0]?.msg);
            return false;
        }
    }

    const processCabCapital = useCallback(() => {
        if (cabCapital.email) {
            setFormulario({
                email: cabCapital.email,
                bussiness_name: cabCapital.fullName,
                password: cabCapital.password,
                confirmPassword: cabCapital.password
            });
            sendRequest({
                email: cabCapital.email,
                bussiness_name: cabCapital.fullName,
                password: cabCapital.password,
                confirmPassword: cabCapital.password
            });
        }
    }, [cabCapital])


    useEffect(() => {
        processCabCapital()
    }, [])

    // Validaciones en tiempo real para paso de registro
    useEffect(() => {
        user && setPaso(2);
    }, [user, setPaso])


    return (
        <div className="formulario-registro direction-column justify-center align-start">
            <div className="espacio-titulo direction-row justify-start align-start">
                <img src="/img/logo-cab-risk.png" className="logo-img" alt="logo" />
                <img src="/img/titulo-cab-risk.png" className="titulo-img" alt="titulo" />
            </div>
            <p className="titulo-1">{languajeJson[language].SignUp.Step1.titulo1}</p>
            <p className="titulo-2">{languajeJson[language].SignUp.Step1.titulo2}</p>
            <div className="espacio-formulario direction-column justify-start align-start scroll-custom">
                <div className="espacio-inputs direction-column justify-start align-start">
                    <div className="espacio-input direction-column justify-start align-start">
                        <p className="titulo-2">{languajeJson[language].SignUp.Step1.texto1}</p>
                        <input
                            type="text"
                            className="input"
                            placeholder={languajeJson[language].SignUp.Step1.placeholder1}
                            value={formulario.bussiness_name}
                            onChange={(e) => setFormulario({ ...formulario, bussiness_name: e.target.value })}
                        />
                    </div>
                    <div className="espacio-input">
                        <p className="titulo-2">{languajeJson[language].SignUp.Step1.texto2}</p>
                        <input
                            type="text"
                            className={formulario.email !== "" && !validarCorreo(formulario.email) ? "input-error" : "input"}
                            placeholder={languajeJson[language].SignUp.Step1.placeholder2}
                            value={formulario.email}
                            onChange={(e) => setFormulario({ ...formulario, email: e.target.value })}
                            autocomplete="new-text"
                        />
                        {formulario.email !== "" && !validarCorreo(formulario.email) && <p className="texto-error">{languajeJson[language].SignIn.textoError}</p>}
                    </div>
                    <div className="espacio-input">
                        <p className="titulo-2">{languajeJson[language].SignUp.Step1.texto3}</p>
                        <input
                            type={ojo}
                            className={formulario.password !== "" && !validarContrasena(formulario.password) ? "input-error" : "input"}
                            placeholder={languajeJson[language].SignUp.Step1.placeholder3}
                            value={formulario.password}
                            onChange={(e) => setFormulario({ ...formulario, password: e.target.value })}
                            autocomplete="new-password"
                        />
                        {formulario.password !== "" && !validarContrasena(formulario.password) && (
                            <p className="texto-error">{languajeJson[language].PasswordRecovery.textoError1}</p>
                        )}
                        <img
                            src="/img/ojo.png"
                            className={formulario.password !== "" && !validarContrasena(formulario.password) ? "ojo-error" : "ojo"}
                            alt="ojo"
                            onClick={() => setOjo(ojo === "password" ? "text" : "password")}
                        />
                    </div>
                    <div className="espacio-input">
                        <p className="titulo-2">{languajeJson[language].SignUp.Step1.texto4}</p>
                        <input
                            type={ojo}
                            className={formulario.confirmPassword !== "" && formulario.password !== formulario.confirmPassword ? "input-error" : "input"}
                            placeholder={languajeJson[language].SignUp.Step1.placeholder4}
                            value={formulario.confirmPassword}
                            onChange={(e) => setFormulario({ ...formulario, confirmPassword: e.target.value })}
                        />
                        {formulario.confirmPassword !== "" && formulario.password !== formulario.confirmPassword && (
                            <p className="texto-error">{languajeJson[language].PasswordRecovery.textoError2}</p>
                        )}
                        <img
                            src="/img/ojo.png"
                            className={formulario.confirmPassword !== "" && formulario.password !== formulario.confirmPassword ? "ojo-confirm-error" : "ojo"}
                            alt="ojo"
                            onClick={() => setOjo(ojo === "password" ? "text" : "password")}
                        />
                    </div>
                </div>
                <Link to="/" className="link"><p>{languajeJson[language].SignIn.texto1}</p></Link>
                <button className={`boton-continuar ${validarFormulario() ? "boton-enabled" : "boton-disabled"}`} onClick={() => cambiarPaso()}>{languajeJson[language].SignUp.Step1.textoBoton}</button>
            </div>
        </div>
    );
}

export default Step1;