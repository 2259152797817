import React, { useState, useContext, useEffect, useCallback, useRef } from 'react'

//Imports librerias externas
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { toast, Toaster } from 'react-hot-toast';

//Imports componentes principales
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

//Imports componentes auxiliares
import LateralVector from '../../Utils/LateralVector/LateralVector';
import GeneralContext from '../../context/GeneralContext'
import Loader from '../../Utils/loader/loader';
import { ENCRIPTION_KEY_2_CAB_CAPITAL, ENCRIPTION_KEY_CAB_CAPITAL, STRIPE_KEY } from '../../Config/config'
import { eliminarSesion } from '../../Utils/Utils';
import EEUU from '../../Utils/Flags/EEUU';
import SPAIN from '../../Utils/Flags/SPAIN';

import "./assets/SignUp.scss"


const stripePromise = loadStripe(STRIPE_KEY);


const SignUp = () => {

    const location = useLocation();
    const [datos_cab_capital, setDatos_cab_capital] = useState({})
    let datos_cab = useRef({})
    //Context
    const GC = useContext(GeneralContext);
    const { user, modificarUser, isLoading, language, modificarLanguaje } = GC;

    const navigate = useNavigate();
    //-------------------------------------------------------------------------------------------------------

    const [paso, setPaso] = useState(1) // State para asignacion de paso de registro

    const renderPaso = () => {
        fromCabCapital()
        switch (paso) {
            case 1: return <Step1 cabCapital={datos_cab} setPaso={setPaso} GC={GC} toast = {toast} />
            case 2: return <Step2 setPaso={setPaso} GC={GC} />
            case 3: return <Step3 setPaso={setPaso} GC={GC} />
            case 4: return <Elements stripe={stripePromise}><Step4 GC={GC} /></Elements>
            default: return <Step1 setPaso={setPaso} GC={GC} />
        }
    }

    const logOut = async () => {
        modificarUser(null);
        eliminarSesion();
        navigate("/");
    }

    const fromCabCapital = useCallback(() => {
        const param = new URLSearchParams(location.search)?.get('token')
        if (param) {
            const token = param.toString();
            //// DECODE DATA
            const urlSafeDecodedToken = token
                .replace(/-/g, "+")
                .replace(/_/g, "/");

            const tokenBase64Decoded = atob(urlSafeDecodedToken);

            const decryptedCiphertext1 = CryptoJS.AES.decrypt(
                tokenBase64Decoded,
                ENCRIPTION_KEY_2_CAB_CAPITAL
            ).toString(CryptoJS.enc.Utf8);

            const decryptedData = CryptoJS.AES.decrypt(
                decryptedCiphertext1,
                ENCRIPTION_KEY_CAB_CAPITAL
            ).toString(CryptoJS.enc.Utf8);

            const jsonParsed = JSON.parse(decryptedData)

            datos_cab = jsonParsed
        }
    }, [])

    return (
        <div className="general-body-sign-up">
            <Toaster position="top-right" reverseOrder={false}/>
            {isLoading && <Loader />}
            <header>
                <div className="direction-row justify-between align-center" style={{ width: 180, margin: 20 }}>
                    <div className="direction-row justify-between align-center" style={{ width: 120, margin: 20 }}>
                        <div onClick={() => modificarLanguaje("ES")}><SPAIN language={language} /></div>
                        <div onClick={() => modificarLanguaje("EN")}><EEUU language={language} /></div>
                    </div>
                    {user && <img src="/img/box-arrow-in-right.svg" className="img-arrow" alt="flecha" onClick={() => logOut()}></img>}
                </div>
            </header>
            <LateralVector lado="izq" mostrarInfo={(paso !== 3)} results={false} />
            <div className="componente-mitad direction-column justify-center align-center">{renderPaso()}</div>
        </div>
    );
}

export default SignUp;