import React, {useState, useEffect, useRef} from 'react'

//Imports librerias externas
import axios from 'axios';

//Imports componentes auxiliares
import GeneralModal from '../../Utils/GeneralModal/GeneralModal'
import { guardarSesion } from '../../Utils/Utils'
import { URL_API } from '../../Config/config';
import languageJson from '../../Utils/language.json';

const Step3 = ({setPaso, GC}) => {
    const paragraphContainerRef = useRef(null);

    // Configurar encabezados personalizados para evitar problemas de CORS
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

    //Context
    const { user, modificarUser, language } = GC;

    const [boton, setBoton] = useState("boton-disabled") // State para claseName de boton "Continuar"

    const [modalIsOpen, setModalIsOpen] = useState(true); //State para manejo de modales

    const [seccion, setSeccion] = useState(languageJson[language].SignUp.Step3.seccion1) // State para className de input
   const [seccionKey, setseccionKey] = useState("Acuerdos")
    const [terminosYCondiciones, setTerminosYCondiciones] = useState({terminos: false, tratamiento: false}) // State para terminos y condiciones

    //TODO Establecer comprobacion general
    const comprobarTerminos = React.useCallback(() => {
        return (terminosYCondiciones.terminos &&  terminosYCondiciones.tratamiento)
    }, [terminosYCondiciones])


    async function aceptarTYC() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.bearer}`
        try{
            const response = await axios.put(URL_API + 'api/authentication/accept-terms-and-conditions', {userLoginId: user.email})

            let newUser = user
            newUser.terms_conditions = true

            modificarUser(newUser)
            guardarSesion(newUser)

            return response.data.success
        }catch(error){
            console.error('Error:', error);
            return false;
        }
    }

    const cambioPaso = async () => {
        if(comprobarTerminos()){
            const aceptar = await aceptarTYC();
            aceptar && setPaso(4);
        }
    }

    const handleClick = (seccionKey, seccion) => {
        setseccionKey(seccionKey);
        setSeccion(seccion);

        if (paragraphContainerRef) {
            paragraphContainerRef.current.scrollTo(0,0);
        }
    }

     // Validaciones en tiempo real para paso de registro
     useEffect(()=>{
        user?.terms_conditions && setPaso(4); //Validacion de usuario
        if(comprobarTerminos())
            setBoton("boton-enabled");
        else if(boton === "boton-enabled")
            setBoton("boton-disabled");
    }, [comprobarTerminos, boton, setBoton, user, setModalIsOpen, setPaso])
    return ( 
        <GeneralModal modalIsOpen={modalIsOpen} setIsOpen={setModalIsOpen} contentLabel={"Terms"} contentStyles={{padding: "0px", borderRadius: "8px"}}>
            <div className="modal-terminos">
                <div className="menu direction-column justify-center align-start">
                <div className="fila-info" 
                    onClick={() => handleClick("Acuerdos", languageJson[language].SignUp.Step3.seccion1)}
                    >
                    <p className={seccion === languageJson[language].SignUp.Step3.seccion1 ? "numero-info-selected" : "numero-info-inselected"}>1</p>
                    <p className="texto-info">{languageJson[language].SignUp.Step3.seccion1}</p>
                </div>
                <div className="fila-info" 
                    onClick={() => handleClick("Operaciones", languageJson[language].SignUp.Step3.seccion2)}>
                    <p className={seccion === languageJson[language].SignUp.Step3.seccion2 ? "numero-info-selected" : "numero-info-inselected"}>2</p>
                    <p className="texto-info">{languageJson[language].SignUp.Step3.seccion2}</p>
                </div>
                <div className="fila-info"
                    onClick={() => handleClick("Cambio de claves o identificación personal", languageJson[language].SignUp.Step3.seccion3)}>
                    <p className={seccion === languageJson[language].SignUp.Step3.seccion3 ? "numero-info-selected" : "numero-info-inselected"}>3</p>
                    <p className="texto-info">{languageJson[language].SignUp.Step3.seccion3}</p>
                </div>
                <div className="fila-info" 
                    onClick={() => handleClick("Condiciones generales del servicio de Internet", languageJson[language].SignUp.Step3.seccion4)}
                    >
                    <p className={seccion === languageJson[language].SignUp.Step3.seccion4 ? "numero-info-selected" : "numero-info-inselected"}>4</p>
                    <p className="texto-info">{languageJson[language].SignUp.Step3.seccion4}</p>
                </div>
                <div className="fila-info"
                    onClick={() => handleClick("Condiciones de uso de desarrollo de software", languageJson[language].SignUp.Step3.seccion5)}>
                    <p className={seccion ===languageJson[language].SignUp.Step3.seccion5 ? "numero-info-selected" : "numero-info-inselected"}>5</p>
                    <p className="texto-info">{languageJson[language].SignUp.Step3.seccion5}</p>
                </div>
                <div className="fila-info"
                    onClick={() => handleClick("Modificaciones", languageJson[language].SignUp.Step3.seccion6)}>
                    <p className={seccion === languageJson[language].SignUp.Step3.seccion6 ? "numero-info-selected" : "numero-info-inselected"}>6</p>
                    <p className="texto-info">{languageJson[language].SignUp.Step3.seccion6}</p>
                </div>
                </div>
                <div className="texto-terms direction-column justify-center align-start">
                <p className="titulo">{languageJson[language].SignUp.Step3.titulo1}</p>
                <div className="subtitulo-espacio">
                    <p className="subtitulo">{seccion}</p>
                    <div className="linea"></div>
                </div>
                <div className="parrafo" ref={paragraphContainerRef}>
                    <p className="texto-parrafo">{languageJson[language].SignUp.Step3[seccionKey]}</p>
                </div>
                <div className="aceptar-terms">
                    <div className="checks">
                    <div className="linea-check">
                        <input
                        type="checkbox"
                        className="check"
                        onChange={(e) => setTerminosYCondiciones({ ...terminosYCondiciones, terminos: e.target.checked })}
                        />
                        <span className="check-mark"></span>
                        <p className="texto">{languageJson[language].SignUp.Step3.aceptoTerminos}</p>
                    </div>
                    <div className="linea-check">
                        <input
                        type="checkbox"
                        className="check"
                        onChange={(e) => setTerminosYCondiciones({ ...terminosYCondiciones, tratamiento: e.target.checked })}
                        />
                        <span className="check-mark"></span>
                        <p className="texto">{languageJson[language].SignUp.Step3.autorizoTratamiento}</p>
                    </div>
                    </div>
                    <button className={"boton-continuar " + boton} onClick={() => (terminosYCondiciones.terminos && terminosYCondiciones.tratamiento ? cambioPaso() : null)}>
                    {languageJson[language].SignUp.Step3.textoBotonCrearCuenta}
                    </button>
                </div>
                </div>
            </div>
        </GeneralModal>
     );
}
 
export default Step3;