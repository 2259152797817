import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import DatePicker from 'react-date-picker';
// import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";

import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

function CABCalendar({
  label,
  placeholder,
  value,
  error,
  errorMessage,
  onChangeValue,
}) {
  const [selectedDate, setSelectedDate] = useState(null);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChangeValue(date);
  };

  return (
    <div>
      <p className="titulo-2">{label}</p>
      <div className="calendario-input">
        <DatePicker
          onChange={handleDateChange}
          value={selectedDate}
          clearIcon={null}
          format="dd/MM/yyyy"
          maxDate={new Date()}
          placeholderText={placeholder}
        />
      </div>
      {errorMessage != "" && <p className="text-error">{errorMessage}</p>}
    </div>
  );
}

CABCalendar.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChangeValue: PropTypes.func,
};

export default CABCalendar;